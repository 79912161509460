const PriceBRL = ({ value }) => {
	const valueString = `${value}`;
	const valueRemoved = valueString.replace(/\D/g, "");
	const sizeSlice = valueRemoved.length - 2;
	let newValue;
	if (sizeSlice === 0) {
	  newValue = [valueRemoved.slice(sizeSlice), ',', "00"].join('');
	} else {
	  newValue = [valueRemoved.slice(0, sizeSlice), ',', valueRemoved.slice(sizeSlice)].join('');
	}
	return "R$ "+newValue;
};

export default PriceBRL;
