import React from "react";

import { Avatar, CardHeader } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import { Person, WhatsApp } from '@material-ui/icons';
import CustomToolTip from "../ToolTips";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
	infoTagsData: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "left",
		bottom: 0,
		right: 3,
		fontSize: "0.9em",
	},
	infoTag: {
		cursor: "pointer",
		display: "flex",
		color: "gray",
		paddingTop: 2,
		paddingRight: 5,
		marginRight: 1,
		borderRadius: 3,
	},
	infoTagBlue: {
		color: "RoyalBlue",
	},
	infoTagGreen: {
		color: "#00a859",
	},
	infoButton: {
		padding: 1,
		height: 15,
		fontSize: "115%",
		cursor: "pointer",
	},
	infoQueue: {
		width: "15px",
		height: "15px",
		borderRadius: 10,
	}

}));

const TicketInfo = ({ contact, ticket, onClick }) => {
	const classes = useStyles();

	return (
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
			title={`${contact.name} #${ticket.id}`}
			subheader={
				<div className={classes.infoTagsData}>
					{ticket.queue && (
						<>
							<span className={classes.infoQueue} style={{ backgroundColor: ticket?.queue?.color }}></span>
							<span className={classes.infoTag}> &nbsp;{ticket?.queue?.name}</span>
						</>
					)}
					{ticket.whatsappId && (
						<CustomToolTip title={i18n.t("ticketsList.connectionTitle")} >
							<div className={classes.infoTag}>
								<WhatsApp className={`${classes.infoButton} ${classes.infoTagGreen}`} />
								{ticket.whatsapp?.name}
							</div>
						</CustomToolTip>
					)}
					{ticket.userId && (
						<CustomToolTip title={i18n.t("ticketsList.userTitle")} >
							<div className={classes.infoTag}>
								<Person className={`${classes.infoButton} ${classes.infoTagBlue}`} />
								{ticket.user?.name}
							</div>
						</CustomToolTip>
					)}
				</div>
			}
		/>
	);
};

export default TicketInfo;
