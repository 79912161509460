const messages = {
  en: {
    translations: {
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Please login!",
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "Login",
        textAccessCode: "We have sent an access code to your email, please check your email. If you can't find it, check your spam folder.",
        form: {
          email: "Email",
          password: "Password",
          accessCode: "Access code",
        },
        buttons: {
          submit: "Enter",
          register: "Don't have an account? Register!",
        },
      },
      auth: {
        toasts: {
          success: "Login successfully!",
        },
      },

      reports: {
        filters: {
          today: "Today",
          days: "Days",
          month: "Month",
          filterBy: "Filter by",
          modal: {
            title: "More filter options",
            dateInitial: "date Initial",
            dateFinal: "Date Final",
            button: {
              title: "Filter"
            }
          }
        },
        charts: {
          yearly: {
            title: "Tickets Finished in"
          }
        },
        usersRankinglist: {
          title: "Ranking Attendances",
          name: "Name"
        },
        status: {
          inAttendance: "in Attendance",
          waiting: "Waiting",
          closed: "Closed",
          unassigned: "Unassigned"
        },
      },

      dashboard: {
        charts: {
          perDay: {
            title: "Tickets today: ",
          },
        },
        messages: {
          inAttendance: {
            title: "In Service"
          },
          waiting: {
            title: "Waiting"
          },
          closed: {
            title: "Closed"
          }
        }
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection deleted sucessfully!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? It cannot be reverted.",
          disconnectTitle: "Disconnect",
          disconnectMessage: "Are you sure? You'll need to read QR Code again.",
        },
        buttons: {
          add: "Add WhatsApp",
          disconnect: "Disconnect",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connectiing",
        },
        toolTips: {
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },
        },
        table: {
          name: "Name",
          status: "Status",
          number: "Number",
          lastUpdate: "Last Update",
          default: "Default",
          actions: "Actions",
          session: "Session",
        },
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          name: "Name",
          default: "Default",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      qrCode: {
        message: "Read QrCode to start the session",
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted sucessfully!",
        },
        searchPlaceholder: "Search ...",
        confirmationModal: {
          deleteTitle: "Delete",
          deleteAllTitle: "Delete All",
          importTitle: "Import Contacts",
          deleteMessage: "Are you sure you want to delete this contact? All related tickets will be lost.",
          deleteAllMessage: "Are you sure you want to delete all contacts? All related tickets will be lost.",
          importMessage: "Do you want to import all phone contacts?",
        },
        buttons: {
          import: "Import Contacts",
          add: "Add Contact",
          export: "Export Contacts",
          delete: "Delete All Contacts"
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Actions",
        },
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "Whatsapp number",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Quick Reply saved successfully.",
        tip: "Type @ for variable options."
      },
      planModal: {
        title: {
          add: "Add Plan",
          edit: "Edita Plan",
        },
        form: {
          name: "Name",
          description: "Description",
          price: "Price",
          status: "Active",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Plan saved successfully."
      },      
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        form: {
          name: "Name",
          color: "Color",
          order: "Order",
          greetingMessage: "Greeting Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: {
            title: "Profile",
            role: {
              admin: "Administrator",
              dashboard: "Dashboard",
              finance: "Finance",
              super: "System Manager",
              supervisor: "Supervisor",
              user: "User",
            },
          },
          whatsapp: "Default Connection",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "DRAG AND DROP FILES INTO THE FIELD BELOW",
          titleFileList: "Lista de archivos"
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Sectors",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolved" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search tickets and messages.",
        },
        buttons: {
          showAll: "All",
        },
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search for users",
        fieldQueueLabel: "Transfer to queue",
        fieldConnectionLabel: "Transfer to connection",
        fieldQueuePlaceholder: "Please select a queue",
        fieldConnectionPlaceholder: "Please select a connection",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Queue",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No tickets found with this status or search term.",
        connectionTitle: "Connection that is currently being used.",
        userTitle: "Username assigned this ticket.",
        buttons: {
          assignMe: "Assign Me",
          accept: "Accept",
          acceptBeforeBot: "Accept",
          spy: "Espiar",
          start: "Start",
          cancel: "Cancel"
        },
        acceptModal: {
          title: "Aceitar atendimento",
          queue: "Escolha a fila"
        }
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search for a contact",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          reports: "Reports",
          connections: "Connections",
          tickets: "Tickets",
          contacts: "Contacts",
          quickAnswers: "Quick Answers",
          queues: "Sectors",
          tags: "Tags",
          api: "API",
          administration: "ADMINISTRATION",
          users: "Users",
          settings: "Settings",
          version: "Version",
          super: {
            title: "Multi Tennancy",
            tennants: "Manage Tennants",
          },
        },
        appBar: {
          user: {
            profile: "Profile",
            logout: "Logout",
          },
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      queues: {
        title: "Queues",
        table: {
          name: "Name",
          color: "Color",
          order: "Order",
          greeting: "Greeting message",
          actions: "Actions",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      quickAnswers: {
        title: "Quick Answers",
        table: {
          shortcut: "Shortcut",
          message: "Quick Reply",
          actions: "Actions",
        },
        buttons: {
          add: "Add Quick Reply",
        },
        toasts: {
          deleted: "Quick Reply deleted successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick Reply: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      plans: {
        title: "Plans",
        table: {
          name: "Name",
          description: "Description",
          price: "Price",
          status: "Active",
          actions: "Actions",
        },
        buttons: {
          add: "Add Plan",
        },
        toasts: {
          deleted: "Plan deleted successfully.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Plan: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      users: {
        title: "Users",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          whatsapp: "Default Connection",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted sucessfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open tickets will be moved to queue.",
        },
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle:
            "Are you sure you want to delete this Tag?",
          deleteMessage: "This action cannot be undone.",
        },
        confirmModalDeleteContact: {
          deleteTitle:
            "Are you sure you want to unlink this Tag Contact?",
          deleteMessage: "This action cannot be undone.",
        },        
        table: {
          name: "Name",
          color: "Color",
          tickets: "Registers w Tags",
          actions: "Actions",
        },
        buttons: {
          add: "New Tag",
        },
        forms: {
          inputLabel: "Tags"
        },
        toasts: {
          deleted: "Tag deleted sucessfully.",
          contactDeleted: "Contact deleted sucessfully.",
        },
        info: {
          minimumCharacters: "Enter at least 3 characters.",
        } 
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        support: "Support",
        activate: "activate",
        settings: {
          userCreation: {
            name: "User creation",
            note: "If enabled, users will be able to fill in the form on the frontend.",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          timeCreateNewTicket: {
            name: "WELCOME MESSAGE AFTER",
            note: "Select the time it will take to open a new ticket if the customer contacts you again",
            options: {
              "10": "10 segund",
              "30": "30 segunds",
              "60": "1 minute",
              "300": "5 minutes",
              "1800": "30 minutes",
              "3600": "1 HOURS",
              "7200": "2 HOURS",
              "21600": "6 HOURS",
              "43200": "12 HOURS",
              "86400": "24 HOURS",
              "172800": "48 HOURS",
            },
          },
          hoursCloseTicketAuto: {
            name: "CLOSE TICKET AFTER (HOURS)",
            note: "Select the time, in hours, that tickets will be automatically closed",
            options: {
              "0": "NEVER",
              "1": "1 HOUR",
              "2": "2 HOURS",
              "4": "4 HOURS",
              "6": "6 HOURS",
              "12": "12 HOURS",
              "24": "24 HOURS",
              "36": "36 HOURS",
              "48": "48 HOURS",
              "60": "60 HOURS",
              "72": "72 HOURS"
            },
          },
          call: {
            name: "ACCEPT CALLS",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          queuedTicketsOnly: {
            name: "NO QUEUE: FOR ADMIN ONLY",
            note: "If enabled, only admin will be able to see tickets without queue.",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          CheckMsgIsGroup: {
            name: "IGNORE GROUP MESSAGES",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          ticketWaitingNoShowMessagem: {
            name: "TICKET PENDING: DO NOT SHOW MESSAGE",
            note: "Enabled: if ticket is pending, not show message.",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          sendMessageToSelectQueue: {
            name: "MESSAGE TO SELECT QUEUE",
            note: "If enabled, customers will receive a message to select a queue from the menu.",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          userSpyTicket: {
            name: "User can spy ticket",
            note: "If enabled, users will be able to view tickets in WAITING.",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            resolve: "Resolve",
            reopen: "Reopen",
            accept: "Accept",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Type a message or press ''/'' to use the registered quick responses",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        signMessage: "Sign",
      },
      contactDrawer: {
        header: "Contact details",
        buttons: {
          edit: "Edit contact",
        },
        extraInfo: "Other information",
      },
      ticketOptionsMenu: {
        delete: "Delete",
        transfer: "Transfer",
        confirmationModal: {
          title: "Delete ticket #",
          titleFrom: "from contact ",
          message: "Attention! All ticket's related messages will be lost.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        delete: "Delete",
        reply: "Reply",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
      },
      variables: {
        list: {
          // Contact vars
          contact_firstName: "Contacto: Fist name",
          contact_fullName: "Contacto: Full name",
          contact_email: "Contacto: E-mail",
          contact_phone: "Contacto: Telephone",
          contact_avatar: "Contacto: Avatar",
          contact_dateCreate: "Contacto: Date create",
          contact_dateUpdate: "Contacto: Date update",

          // Ticket vars
          ticket_id: "Ticket: ID",
          ticket_status: "Ticket: Status",
          ticket_lastMessage: "Ticket: Last message",
          ticket_dateCreation: "Ticket: Date createo",
          ticket_dateUpdate: "Ticket: Date update",

          // User vars: use if exists
          user_name: "User: Name",
          user_email: "User: E-mail",

          // Misc
          break_message: "Break message",
          greeting: "Greettinf",
          year: "Year",
          month: "Month",
          day: "Day",
          hour: "Hour",
          minute: "minute",
          second: "Segund",
          date_iso: "Date ISO",
          date_us: "Date US",
          date_br: "Date BR",
          protocol: "Protocol",
        }
      },
      super: {
        title: "Multi Tennancy Area",
        settings: {
          title: "Manage Tennant's Settings",
          dueDate: {
            name: "Due date",
            note: "Due date",
          },
          billingUrl: {
            name: "billing Url",
            note: "billing Url",
          },
          billingPrice: {
            name: "billing Price",
            note: "billing Price",
          },
          modulesCampaigns: {
            name: "Campaigns Module",
            note: "Campaigns Module",
            options: {
              nothing: "No instalado",
              install: "Instalar módulo",
              enabled: "Activado",
              disabled: "Desactivado",
            }
          },
          accessCode: {
            name: "Email Access Code",
            note: "Request Access Code by Email",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            }
          },
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "There must be at lest one default WhatsApp connection.",
        ERR_NO_PERMISSION_CREATE_CONNECTION_LIMIT: "You've reached the conection creation limit, please contact Bits TI to adquire additional licenses.",
        ERR_NO_DEF_WAPP_FOUND: "No default WhatsApp found. Check connections page.",
        ERR_WAPP_NOT_INITIALIZED: "This WhatsApp session is not initialized. Check connections page.",
        ERR_WAPP_CHECK_CONTACT: "Could not check WhatsApp contact. Check connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
        ERR_WAPP_DOWNLOAD_MEDIA: "Could not download media from WhatsApp. Check connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG: "Error sending WhatsApp message. Check connections page.",
        ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "There's already an open chat for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please login.",
        ERR_USER_CREATION_DISABLED: "User creation was disabled by administrator.",
        ERR_NO_PERMISSION: "You don't have permission to access this resource.",
        ERR_NO_PERMISSION_INDEX: "You don't have permission to access this resource (INDEX).",
        ERR_NO_PERMISSION_UPDATE_SETTING: "You don't have permission to update this resource.",
        ERR_NO_PERMISSION_UPDATE_USER: "You don't have permission to update users.",
        ERR_NO_PERMISSION_CREATE_USER: "You don't have permission to create users.",
        ERR_NO_PERMISSION_CREATE_USER_LIMIT: "You've reached the user creation limit, please contact to adquire additional licenses.",
        ERR_NO_PERMISSION_REMOVE_USER: "You don't have permission to remove users.",
        ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_TICKET_FOUND: "No chat found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error while creating message on database.",
        ERR_CREATING_TICKET: "Error while creating chat on database.",
        ERR_FETCH_WAPP_MSG: "Error fetching the message in WhtasApp, maybe it is too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "This color is already in use, pick another one.",
        ERR_WAPP_GREETING_REQUIRED: "Greeting message is required if there is more than one queue.",
      },
    },
  },
};

export { messages };
