import React, { createContext } from "react";

import useSettings from "../../hooks/useSettings";

const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
	const { loading, settings } = useSettings();

	return (
		<SettingsContext.Provider value={{ settings, loading }}>
			{children}
		</SettingsContext.Provider>
	);
};

export { SettingsContext, SettingsProvider };
