import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18n.t("validation.too_short"))
    .max(30, i18n.t("validation.too_long"))
    .required(i18n.t("validation.required")),
  valueCredit: Yup.string()
    .min(2, i18n.t("validation.too_short"))
    .required(i18n.t("validation.required")),
  dns: Yup.string()
    .min(5, i18n.t("validation.too_short"))
    .required(i18n.t("validation.required")),
  typeServer: Yup.string()
    .required(i18n.t("validation.required")),
});

const MsServerModal = ({ open, onClose, msServerId }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    description: "",
    dns: "",
    valueCredit: 0,
    typeServer: "",
    status: true,
  };
  const [msServer, setMsServer] = useState(initialState);

  const changeValueCredit = async (e) => {
    const valueString = `${e.valueCredit}`;
    const valueRemoved = valueString.replace(/\D/g, "");
    const sizeSlice = valueRemoved.length - 2;
    let newValue;
    if (sizeSlice === 0) {
      newValue = [valueRemoved.slice(sizeSlice), ',', "00"].join('');
    } else {
      newValue = [valueRemoved.slice(0, sizeSlice), ',', valueRemoved.slice(sizeSlice)].join('');
    }
    setMsServer({ ...e, 'valueCredit': newValue });
  };

  useEffect(() => {
    const fetchSession = async () => {
      if (!msServerId) return;

      try {
        const { data } = await api.get(`msServers/${msServerId}`);
        changeValueCredit(data);

      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [msServerId]);

  const handleSaveMsServer = async (values) => {
    // const msServerData = { ...values, msServer };
    try {
      if (msServerId) {
        await api.put(`/msServers/${msServerId}`, values);
      } else {
        await api.post("/msServers", values);
      }
      toast.success(i18n.t("msServerModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setMsServer(initialState);
  };

  const handleValueCreditOnChange = (e) => {

    const valueString = `${e.target.value}`;
    const valueRemoved = valueString.replace(/\D/g, "");
    const sizeSlice = valueRemoved.length - 2;
    const newValue = [valueRemoved.slice(0, sizeSlice), ',', valueRemoved.slice(sizeSlice)].join('');

    return newValue;

  };


  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {msServerId
            ? `${i18n.t("global.buttons.edit")} ${i18n.t("msServer.name")}`
            : `${i18n.t("global.buttons.add")} ${i18n.t("msServer.name")}`
          }
        </DialogTitle>
        <Formik
          initialValues={msServer}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveMsServer(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ setFieldValue, values, touched, errors, isSubmitting }) => (

            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine} style={{ minWidth: "200px" }}>
                  <Field
                    as={TextField}
                    label={i18n.t("global.name")}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                  />
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="status"
                        checked={values.status}
                      />
                    }
                    label="Status"
                  />
                </div>
                <div className={classes.multFieldLine} >
                  <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                    <InputLabel>{i18n.t("msServer.typeServer")}</InputLabel>
                    <Field
                      as={Select}
                      required
                      name="typeServer"
                      color="primary"
                      label={i18n.t("msServer.typeServer")}
                      error={touched.typeServer && Boolean(errors.typeServer)}
                      helpertext={touched.typeServer && errors.typeServer}
                      style={{ minWidth: "200px" }}
                    >
                      <MenuItem key={0} value="P2P">P2P</MenuItem>
                      <MenuItem key={1} value="IPTV">IPTV</MenuItem>
                      <MenuItem key={2} value="OTT">OTT</MenuItem>

                    </Field>
                  </FormControl>
                </div>
                <div>
                  <Field
                    as={TextField}
                    label="DNS"
                    multiline
                    minRows={5}
                    fullWidth
                    name="dns"
                    error={
                      touched.dns && Boolean(errors.dns)
                    }
                    helperText={
                      touched.dns && errors.dns
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("global.description")}
                    multiline
                    minRows={5}
                    fullWidth
                    name="description"
                    error={
                      touched.description && Boolean(errors.description)
                    }
                    helperText={
                      touched.description && errors.description
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("msServer.valueCredit")}
                    fullWidth
                    name="valueCredit"
                    error={
                      touched.valueCredit && Boolean(errors.valueCredit)
                    }
                    helperText={
                      touched.valueCredit && errors.valueCredit
                    }
                    variant="outlined"
                    margin="dense"
                    onChange={(value) => {
                      setFieldValue("valueCredit", handleValueCreditOnChange(value));
                    }}
                    InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                  />
                </div>

              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("global.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {msServerId
                    ? i18n.t("global.buttons.okEdit")
                    : i18n.t("global.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(MsServerModal);
