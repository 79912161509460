import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../../context/Settings/SettingsContext";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,

	},

	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},

}));

const Settings = () => {
	const classes = useStyles();

	const { settings } = useContext(SettingsContext);
	const [	userApiToken, setUserApiToken] = useState("disabled");

	useEffect(() => {
		const loadsettings = async () => {
		  const userApiToken = await settings.find(t => t.key === "userApiToken");
		  setUserApiToken(userApiToken?.value);
		}
		loadsettings();
	  }, [settings]);

	return (
		<div className={classes.root}>
			<Container className={classes.container} maxWidth="sm">

				<Paper className={classes.paper}>
					<TextField
						id="api-token-setting"
						readonly
						label="Token Api"
						margin="dense"
						variant="outlined"
						fullWidth
						value={userApiToken}
					/>
				</Paper>

			</Container>
		</div>
	);
};

export default Settings;
