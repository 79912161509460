import { Chip, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import HexToRgb from "../HexToRgb";

export function TagsContainer ({ contact }) {

    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState([]);

    useEffect(() => {
        if (contact) {
            async function fetchData () {
                await loadTags();
                if (Array.isArray(contact.tags)) {
                    setSelecteds(contact.tags);
                }
            }
            fetchData();
        }
    }, [contact]);

    const createTag = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags/list`);
            setTags(data);
        } catch (err) {
            toastError(err);
        }
    }

    const syncTags = async (data) => {
        try { 
            for (let i = 0; i < data.tags.length; i++) {
                delete data.tags[i].contacts
            }
            const { data: responseData } = await api.post(`/tags/sync`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }

    const onChange = async (value, reason) => {
        let optionsChanged = []
        if (reason === 'create-option') {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {

                        if (item.length >2) {
                            const newTag = await createTag({ name: item, color: "#808080" })
                            optionsChanged.push(newTag);
                        }else{
                            toast.error(i18n.t("tags.info.minimumCharacters"));
                        }
                    } else {
                        optionsChanged.push(item);
                    }
                }
            }
            await loadTags();
        } else {
            optionsChanged = value;
        }
        setSelecteds(optionsChanged);

        await syncTags({ contactId: contact.id, tags: optionsChanged });
    }

    return (
        <Paper >
            <Autocomplete
                style={{
                    backgroundColor: 'rgba(0,0,0,0.05)', 
                    color: 'gray', 
                    border: "none" 
                }}
            
                multiple
                size="small"
                options={tags}
                value={selecteds}
                freeSolo
                onChange={(e, v, r) => onChange(v, r)}
                getOptionLabel={(option) => option.name}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            style={{
                                backgroundColor: option.color || 'grey', 
                                color: HexToRgb(option.color) , 
                                border: "1px solid rgba(0,0,0,0.05)"
                            }}
                            label={option.name}
                            {...getTagProps({ index })}
                            size="small"
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Tags" />
                )}
                PaperComponent={({ children }) => (
                    <Paper style={{width: 400, marginLeft: 12}}>
                        {children}
                    </Paper>
                )}
            />
        </Paper>
    )
}