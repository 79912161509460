import React, { useCallback, useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { AttachFile } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";

import {
	Select,
	InputLabel,
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	IconButton,
	Paper,
	Typography,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	FormControl,
	FormControlLabel,
	Switch,
	Tooltip,
	Chip
} from '@material-ui/core';

import { Autocomplete, Alert, AlertTitle } from "@material-ui/lab";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment"
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, } from "lodash";
import useWhatsApps from "../../hooks/useWhatsApps";
import HexToRgb from "../HexToRgb";

import { MixedTags } from "../Tagify/react.tagify";
import "../Tagify/tagify.css";
import "../Tagify/styles.css";


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: 'wrap',
	},
	wrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		with: "100%",
		flexDirection: "row",

	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},
	maxWidth: {
		width: "50%"
	},
	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	viewMediaInputWrapper: {
		maxHeight: "80%",
		display: "flex",
		padding: "10px 13px",
		position: "relative",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: "#eee",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
	},
	uploadInput: {
		display: "none",
	},
	textQuickAnswerContainer: {
		paddingTop: "5px",
		paddingBottom: "5px",
		width: "100%",
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		margin: "1px",
		padding: "1px",
	},
	textFieldMessage: {
		overflowY: "auto",
		minHeight: "160px",
		maxHeight: "160px",
	},
}));


const IOSSwitch = withStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#52d869',
				opacity: 1,
				border: 'none',
			},
		},
		'&$focusVisible $thumb': {
			color: '#52d869',
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
}))
	(({ classes, ...props }) => {
		return (
			<Switch
				focusVisibleClassName={classes.focusVisible}
				disableRipple
				classes={{
					root: classes.root,
					switchBase: classes.switchBase,
					thumb: classes.thumb,
					track: classes.track,
					checked: classes.checked,
				}}
				{...props}
			/>
		);
	});

const CampaignsModal = ({ open, onClose, target, campaignId, reload }) => {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const { loadingWhatsapps, whatsApps } = useWhatsApps();
	const [quickAnswerMessage, setQuickAnswerMessage] = useState("");

	const initialState = {
		name: "",
		scheduleDate: moment().format('YYYY-MM-DDTHH:mm'),
		openTicket: "false",
		delay: "5",
		typeList: "numberList",
		numbers: "",
		msg: "",
		status: "pending"
	};

	const [campaign, setCampaign] = useState(initialState);
	const [openTicket, setOpenTicket] = useState(false);
	const [delay, setDelay] = useState(5);
	const [medias, setMedias] = useState([]);
	const [caption, setCaption] = useState(true);
	const [selectedWhatsapp, setSelectedWhatsapp] = useState(0);
	const [tags, setTags] = useState([]);
	const [selectedTags, setSelectdTags] = useState([]);
	const [whatsAppsConnected, setWhatsAppsConnected] = useState([]);

	const CampaignsSchema = Yup.object().shape({
		name: Yup.string().min(5, i18n.t("validation.too_short")).required(i18n.t("validation.required")),
		numbers: target !== "tags" ? Yup.string().required(i18n.t("validation.required")) : "",
		scheduleDate: Yup.string().required(i18n.t("validation.required"))
	});

	useEffect(() => {
		try {
			(async () => {

				const f = whatsApps.filter(function (e) { return e.status === 'CONNECTED' && e.campaignsEnabled === true; })
				setWhatsAppsConnected(f);
				if (whatsApps.length === 0) return;
			})()
		} catch (err) {

		}
	}, [open]);

	useEffect(() => {
		try {
			(async () => {

				const { data } = await api.get('/tags/list');
				let customList = data.map((c) => ({ id: c.id, name: c.name, color: c.color }));
				if (isArray(customList)) {
					setTags([{ id: "", name: "", color: "" }, ...customList]);
				}

			})()
		} catch (err) {
			toastError(err);
		}
	}, [open]);

	useEffect(() => {
		try {
			(async () => {
				if (whatsApps.length === 0) return;
				let f = 0;
					f = whatsApps.filter(function (e) { return e.isDefault && e.status === 'CONNECTED' && e.campaignsEnabled === true; })
				if (f.length === 0) {//if Connection default not connected
					f = whatsApps.filter(function (e) { return e.status === 'CONNECTED' && e.campaignsEnabled === true; })
				}
				setSelectedWhatsapp(f[0]?.id)
			})()
		} catch (err) {

		}
	}, [open]);

	useEffect(() => {
		if (campaignId) setCampaign(initialState);
	}, [campaignId, initialState]);

	const handleClose = () => {
		campaignId = "";
		setSelectdTags([]);
		setCampaign(initialState);
		onClose();
	};

	const handleSaveCampaigns = async values => {

		const captionValue = medias.length > 1 ? false : caption;

		if (target === "tags" && selectedTags.length === 0) {
			alert("Seleciona uma ou mais Etiquetas/Tags");
			toast.error("Seleciona uma ou mais Etiquetas/Tags");
			return;
		}
		if (medias.length === 0 && quickAnswerMessage.length === 0) {
			alert("O campo " + i18n.t("campaignsModal.form.msg") + " " + i18n.t("validation.required"));
			toast.error("O campo " + i18n.t("campaignsModal.form.msg") + " " + i18n.t("validation.required"));
			return;
		}

		values.msg = quickAnswerMessage;

		const campaignData = {
			...values,
			userId: user.id,
			caption: captionValue,
			whatsappId: selectedWhatsapp,
			openTicket,
			delay,
			target
		};

		if (campaignId) {
			await api.put(`/campaigns/${campaignId}`, campaignData);
		} else {

			const formData = new FormData();
			medias.forEach((media) => {
				formData.append("medias", media);
			});

			for (const [key, value] of Object.entries(campaignData)) {
				formData.append(key, value);
			}
			formData.append("selectedTags", JSON.stringify(selectedTags))

			try {
				await api.post(`/campaigns`, formData);
			} catch (err) {
				toastError(err);
			}

			setMedias([]);

		}

		if (typeof reload == 'function') {
			reload();
		}
		toast.success(i18n.t("campaignsModal.success"));
		handleClose();

	};

	const handleChangeMedias = (e) => {
		if (!e.target.files) {
			return;
		}

		const selectedMedias = Array.from(e.target.files);
		setMedias(selectedMedias);
	};

	const handleCaption = async e => {
		const selectedValue = e.target.checked ? true : false;
		setCaption(selectedValue);
	};


	// Tagify settings object
	const settings = {
		pattern: /@/,  // <- must define "patten" in mixed mode
		dropdown: {
			enabled: 0,
			position: "text"
		},
		whitelist: [
			// Contact vars      
			{ value: i18n.t("variables.list.contact_firstName"), title: "##contact_firstName##" },
			{ value: i18n.t("variables.list.contact_fullName"), title: "##contact_fullName##" },
			{ value: i18n.t("variables.list.contact_email"), title: "##contact_email##" },
			{ value: i18n.t("variables.list.contact_phone"), title: "##contact_phone##" },

			//  // Misc
			{ value: i18n.t("variables.list.greeting"), title: "##greeting##" },
			{ value: i18n.t("variables.list.date_us"), title: "##date_us##" },
			{ value: i18n.t("variables.list.date_br"), title: "##date_br##" },

			{ value: i18n.t("variables.list.break_message") + 1, title: "##break_message##" },
			{ value: i18n.t("variables.list.break_message") + 2, title: "##break_message##" },
			{ value: i18n.t("variables.list.break_message") + 3, title: "##break_message##" },
			{ value: i18n.t("variables.list.break_message") + 4, title: "##break_message##" },
			{ value: i18n.t("variables.list.break_message") + 5, title: "##break_message##" },

		]
	}

	const handleChangeMessage = useCallback(e => {
		setQuickAnswerMessage(e.detail.value);
	}, [])



	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="lg"
				fullWidth
				scroll="paper"
			>
				{whatsAppsConnected.length === 0
					?
					<>
						<DialogTitle id="form-dialog-title">
							<Alert severity="info">
								<AlertTitle>INFO</AlertTitle>
								{i18n.t("campaignsModal.title.noConnected")}
							</Alert>
						</DialogTitle>
						<DialogActions>
							<Button
								onClick={handleClose}
								color="secondary"
								variant="outlined"
							>
								{i18n.t("campaignsModal.buttons.close")}
							</Button>
						</DialogActions>
					</>
					:
					<>
						<DialogTitle id="form-dialog-title">
							{i18n.t("campaignsModal.title.add")}
						</DialogTitle>
						<Formik
							initialValues={campaign}
							enableReinitialize={true}
							validationSchema={CampaignsSchema}
							onSubmit={(values, actions) => {
								setTimeout(() => {
									handleSaveCampaigns(values);
									actions.setSubmitting(false);
								}, 400);
							}}
						>
							{({ touched, errors, isSubmitting, values }) => (
								<Form>

									<DialogContent dividers >
										<Paper elevation={0} variant="outlined" className={classes.wrapper}>
											<div style={{ marginLeft: "20px", width: "50%" }}>
												<Field
													as={TextField}
													label={i18n.t("campaignsModal.form.name")}
													name="name"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
													fullWidth

												/>
												<div className={classes.multFieldLine}>
													<FormControl variant="outlined" margin="dense" className={classes.maxWidth} >
														<InputLabel>{i18n.t("campaignsModal.form.openTicket.name")}</InputLabel>
														<Select
															label={i18n.t("campaignsModal.form.openTicket.name")}
															margin="dense"
															variant="outlined"
															native
															id="openTicket"
															name="openTicket"
															onChange={(e) => setOpenTicket(e.target.value)}
															value={openTicket}
															className={classes.textField}

														>
															<option value="false">
																{i18n.t("campaignsModal.form.openTicket.options.false")}
															</option>
															<option value="true">
																{i18n.t("campaignsModal.form.openTicket.options.true")}
															</option>
															<option value="trueClose">
																{i18n.t("campaignsModal.form.openTicket.options.trueClose")}
															</option>
														</Select>
													</FormControl>


													<FormControl variant="outlined" margin="dense" className={classes.maxWidth} >

														<InputLabel>{i18n.t("campaignsModal.form.connection")}</InputLabel>
														<Select
															as={TextField}
															className={classes.textField}
															margin="dense"
															variant="outlined"
															native
															id="whatsAppId"
															name="whatsAppId"
															value={selectedWhatsapp}
															onChange={(e) => setSelectedWhatsapp(e.target.value)}
															label={i18n.t("campaignsModal.form.msg")}
														>


															{(!loadingWhatsapps &&

																whatsAppsConnected?.map(item => {
																	return <option key={item.id} value={item.id} >{item.name}</option>
																})
																// whatsApps.filter(w => {
																// 	return (w.status === 'CONNECTED');
																// }).map(item => {
																// 	return <option key={item.id} value={item.id} >{item.name}</option>
																// })
															)}
														</Select>
													</FormControl>
												</div>

												{target === "tags" &&
													<FormControl
														variant="outlined"
														fullWidth
														margin="dense"
														multiple
													>

														<Autocomplete
															style={{
																backgroundColor: 'rgba(0,0,0,0.05)',
																color: 'gray',
																border: "none"
															}}

															multiple
															size="small"
															options={tags}
															value={selectedTags}
															onChange={(e, tags) => {
																setSelectdTags(tags);
															}}
															getOptionLabel={(option) => option.name}
															renderTags={(value, getTagProps) =>
																value.map((option, index) => (
																	<Chip
																		variant="outlined"
																		style={{
																			backgroundColor: option.color || 'grey',
																			color: HexToRgb(option.color),
																			border: "1px solid rgba(0,0,0,0.05)"
																		}}
																		label={option.name}
																		{...getTagProps({ index })}
																		size="small"
																	/>
																))
															}
															renderInput={(params) => (
																<TextField {...params} variant="outlined" label={i18n.t("campaignsRepostsSaveListModal.tagName")} placeholder="Tags" />
															)}
															PaperComponent={({ children }) => (
																<Paper style={{ width: 400, marginLeft: 12 }}>
																	{children}
																</Paper>
															)}
														/>

													</FormControl>

												}
												{target === "list" &&
													<Field
														as={TextField}
														maxRows={7}
														minRows={7}
														multiline={true}
														label="Números"
														name="numbers"
														error={touched.numbers && Boolean(errors.numbers)}
														helperText={touched.numbers && errors.numbers}
														variant="outlined"
														margin="dense"
														placeholder="Colocar os números um abaixo do outro Lista de números no formato ddi+ddd+número 555199999999"
														fullWidth />
												}

												<br />
												<div className={classes.textQuickAnswerContainer}>
													<Typography className={classes.heading}>{i18n.t("campaignsModal.form.msg")} | {i18n.t("quickAnswersModal.tip")} </Typography>
													<MixedTags
														settings={settings}
														className={classes.textFieldMessage}
														onChange={handleChangeMessage}
														value=""
														placeholder={i18n.t("quickAnswersModal.tip")}
													/>
												</div>

												<br />

												<div className={classes.multFieldLine}>

													<Field
														as={TextField}
														label={i18n.t("campaignsModal.form.sendAt")}
														type="datetime-local"
														name="scheduleDate"
														InputLabelProps={{
															shrink: true,
														}}
														error={touched.scheduleDate && Boolean(errors.scheduleDate)}
														helperText={touched.scheduleDate && errors.scheduleDate}
														variant="outlined"
														margin="dense"
														fullWidth
													/>

													<FormControl variant="outlined" margin="dense" className={classes.maxWidth} >
														<InputLabel>{i18n.t("campaignsModal.form.delay.name")}</InputLabel>
														<Select
															label={i18n.t("campaignsModal.form.delay.name")}
															margin="dense"
															variant="outlined"
															native
															id="delay"
															name="delay"
															onChange={(e) => setDelay(e.target.value)}
															value={delay}
															className={classes.textField}
														>
															<option value="3">3 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="4">4 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="5">5 {i18n.t("campaignsModal.form.delay.seconds")} ({i18n.t("campaignsModal.form.delay.recommendedMinimum")})</option>
															<option value="6">6 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="7">7 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="8">8 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="9">9 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="10">10 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="11">11 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="12">12 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="13">13 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="14">14 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="15">15 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="20">20 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="25">25 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="30">30 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="45">45 {i18n.t("campaignsModal.form.delay.seconds")}</option>
															<option value="100">1 {i18n.t("campaignsModal.form.delay.minute")}</option>
														</Select>
													</FormControl>
												</div>
												<br />
											</div>

											<div style={{ marginLeft: "20px", width: "50%" }}>
												<input
													multiple
													type="file"
													id="upload-button"
													className={classes.uploadInput}
													onChange={handleChangeMedias}
												/>
												<label htmlFor="upload-button">
													<IconButton
														aria-label="upload"
														component="span"
													>
														<AttachFile className={classes.sendMessageIcons} /> {i18n.t("campaignsModal.form.attachFiles")}
													</IconButton>
												</label>

												{medias.length > 0 && (
													<>
														{medias.length === 1 && (
															<>
																<Typography variant="body2" gutterBottom></Typography>

																<Paper className={classes.paper}>
																	<Tooltip title={i18n.t("campaignsModal.form.caption.note")}>
																		<FormControlLabel
																			control={
																				<IOSSwitch
																					checked={caption}
																					onChange={handleCaption}
																					name="caption"
																				/>
																			} label={i18n.t("campaignsModal.form.caption.name")}
																		/>
																	</Tooltip>
																</Paper>
															</>
														)}
														<Paper
															elevation={0}
															square
															className={classes.viewMediaInputWrapper}
														>
															<Grid item className={classes.gridFiles}>
																<Typography variant="h6" component="div">
																	{i18n.t("uploads.titles.titleFileList")} ({medias.length})
																</Typography>
																<List>
																	{medias.map((value, index) => {
																		return (
																			<ListItem key={index}>
																				<ListItemAvatar>
																					<Avatar alt={value.name} src={URL.createObjectURL(value)} />
																				</ListItemAvatar>
																				<ListItemText
																					primary={`${value.name}`}
																					secondary={`${parseInt(value.size / 1024)} kB`}
																				/>
																			</ListItem>
																		);
																	})}
																</List>
															</Grid>
														</Paper>
													</>
												)}

											</div>

										</Paper>

									</DialogContent>
									<DialogActions>
										<Button
											onClick={handleClose}
											color="secondary"
											disabled={isSubmitting}
											variant="outlined"
										>
											{i18n.t("campaignsModal.buttons.cancel")}
										</Button>
										{(campaign.status !== "sent") && (
											<Button
												type="submit"
												color="primary"
												disabled={isSubmitting}
												variant="contained"
												className={classes.btnWrapper}
											>{i18n.t("campaignsModal.buttons.okSave")}
												{isSubmitting && (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												)}
											</Button>
										)}
									</DialogActions>
								</Form>
							)}
						</Formik>
					</>
				}
			</Dialog>
		</div>
	);
};

export default CampaignsModal;
