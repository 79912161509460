import React, { createContext } from "react";

import useMsServers from "../../hooks/useMsServers";

const MsServersContext = createContext();

const MsServersProvider = ({ children }) => {
	const { loading, msServers } = useMsServers();

	return (
		<MsServersContext.Provider value={{ msServers, loading }}>
			{children}
		</MsServersContext.Provider>
	);
};

export { MsServersContext, MsServersProvider };
