import { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const reducer = (state, action) => {
	if (action.type === "LOAD_SETTINGS") { 
		const msServers = action.payload;

		return [...msServers];
	}

	if (action.type === "UPDATE_SETTINGS") {
		
		const msServers = action.payload;
		const msServersIndex = state.findIndex(s => s.id === msServers.id);

		if (msServersIndex !== -1) {
			state[msServersIndex] = msServers;
			return [...state];
		} else {
			return [msServers, ...state];
		}
	}

	if (action.type === "UPDATE_SESSION") {
		const msServers = action.payload;
		const msServersIndex = state.findIndex(s => s.id === msServers.id);

		if (msServersIndex !== -1) {
			state[msServersIndex].status = msServers.status;
			state[msServersIndex].updatedAt = msServers.updatedAt;
			state[msServersIndex].qrcode = msServers.qrcode;
			state[msServersIndex].retries = msServers.retries;
			return [...state];
		} else {
			return [...state];
		}
	}

	if (action.type === "DELETE_SETTINGS") {
		const msServersId = action.payload;

		const msServersIndex = state.findIndex(s => s.id === msServersId);
		if (msServersIndex !== -1) {
			state.splice(msServersIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useMsServers = () => {
	const [msServers, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/msServers/simpleListMsServers");
				dispatch({ type: "LOAD_SETTINGS", payload: data }); 
				setLoading(false);
			} catch (err) {
				setLoading(false);
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket();

		socket.on("msServers", data => {

			if (data.action === "update" || data.action === "create") {
				dispatch({ type: "UPDATE_SETTINGS", payload: data.setting });
			} 
		});

		socket.on("msServers", data => {
			if (data.action === "delete") {
				dispatch({ type: "DELETE_SETTINGS", payload: data.msServersId });
			}
		});

		socket.on("msServersSession", data => {
			if (data.action === "update") {
				dispatch({ type: "UPDATE_SESSION", payload: data.session });
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	return { msServers, loading };
};

export default useMsServers;



// import api from "../../services/api";

// const useMsServers = (key) => {
// 	const findAll = async () => {
//         const { data } = await api.get("/msServers");
//         return data;
//     }

// 	return { findAll };
// };

// export default useMsServers;
