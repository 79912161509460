
import React from "react"

import { useState, useEffect } from "react";

import { blue } from "@material-ui/core/colors";


import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  Tabs,
  Tab,
  Badge,
  Container,
  ListItemText,
  Box
} from '@material-ui/core';

import {
  AccessTime,
  Done,
  DoneAll,
  Save
} from "@material-ui/icons";

import { Table, TableHead, TableCell, TableRow, TableBody } from "@material-ui/core";
import moment from 'moment';

import { i18n } from "../../translate/i18n";

import api from "../../services/api";

import { Doughnut } from 'react-chartjs-2';

import { makeStyles } from "@material-ui/core/styles"
import TableRowSkeleton from "../TableRowSkeleton";
import CampaignsSaveListModal from "../../components/CampaignsSaveListModal";


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: -5,
    marginBottom: -5,
    paddingTop: 5,
    paddingBottom: 5,
  },

  customCardChart: {
    overflow: "hidden",
    padding: 10,
    height: 300,
  },
  customCard: {
    overflow: "hidden",
    padding: 5,
    margin: 0,
    height: 75,
  },
  customCardPercent: {
    paddingTop: 7,
    paddingLeft: 5
  },
  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },
  ackDoneAllIcon: {
    color: blue[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },
  buttonSave: {
    size: "65%",
    fontSize: "65%",
  },
  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

}))

const CampaignReportsModal = ({ open, onClose, campaignId, reload }) => {

  const classes = useStyles()

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState([]);
  const [sent, setSent] = useState([]);
  const [pending, setPending] = useState([]);
  const [erro, setErro] = useState([]);

  const [received, setReceived] = useState(0);
  const [unread, setUnread] = useState(0);
  const [read, setRead] = useState(0);
  const [campaignsSaveTagsModalOpen, setCampaignSaveTagsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  function CircularProgressWithLabel(props) {
    const m = Math.round((props.value / props.total * 100),);

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" value={m} style={{ "color": props.color }} />
        <Box
          top={2}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(m,)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const doughnutData = {
    labels: [`${i18n.t("campaignsRepostsModal.sent")}: ${sent.length}`, `${i18n.t("campaignsRepostsModal.pending")}: ${pending.length}`, `${i18n.t("campaignsRepostsModal.error")}: ${erro.length} `],
    datasets: [
      {
        label: '# of Votes',
        data: [sent.length, pending.length, erro.length, 0],
        backgroundColor: [
          '#4caf50',
          '#ff9800',
          '#f44336',
          'rgb(250, 250, 250)',
        ],
      },
    ],
  };

  const doughnutOptions = {
    maintainAspectRatio: false,
  };

  const renderMessageAck = (ack) => {
    if (ack === 0) {
      return <><AccessTime fontSize="small" className={classes.ackIcons} /> {i18n.t("campaignsRepostsModal.unreceived")}</>;
    }
    if (ack === 1) {
      return <><Done fontSize="small" className={classes.ackIcons} /> {i18n.t("campaignsRepostsModal.unreceived")}</>;
    }
    if (ack === 2) {
      return <><DoneAll fontSize="small" className={classes.ackIcons} /> {i18n.t("campaignsRepostsModal.unread")}</>;
    }
    if (ack === 3 || ack === 4) {
      return <><DoneAll fontSize="small" className={classes.ackDoneAllIcon} /> {i18n.t("campaignsRepostsModal.read")}</>;
    }
  };

  useEffect(() => {
    let Fetch;
    setLoading(true);
    const delayDebounceFn = (e) => {

      Fetch = setTimeout(async () => {


        if (campaignId?.id) {
          const { data } = await api.get(`/campaignsSends/${campaignId.id}`);
          const countSent = await data.campaignsSends.filter(function (e) { return e.status === 'sent'; })
          const countPending = await data.campaignsSends.filter(function (e) { return e.status === 'pending'; })
          const countErro = await data.campaignsSends.filter(function (e) { return e.status === 'error'; })
          const countReceived = await data.campaignsSends.filter(function (e) { return e.ack === 1; })
          const countUnread = await data.campaignsSends.filter(function (e) { return e.ack === 2; })
          const countRead = await data.campaignsSends.filter(function (e) { return e.ack >= 3; })

          setUnread(countUnread);
          setRead(countRead);

          setReceived(countReceived);
          setTotal(data.count);
          setSent(countSent);
          setPending(countPending);
          setErro(countErro);
          setLoading(false);
        }

        if (campaignId?.status === "pending") {
          delayDebounceFn(10000);
        } else {
          delayDebounceFn(30000);
        }
        setLoading(false);
      }, e);

    }
    if (campaignId?.id) { delayDebounceFn(1000); }
    return () => {
      clearTimeout(Fetch);
    };
  }, [campaignId]);


  const handleClose = () => {
    campaignId = [];
    onClose();
    setTotal([]);
    setSent([]);
    setPending([]);
    setErro([]);

    setValue(0);
    reload();
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleOpenCampaignSaveTagsModal = (id, status, infoName, infoStatus, infoCount) => {
    const payload = { id, status, infoName, infoStatus, infoCount }
    setSelectedData(payload);
    setCampaignSaveTagsModalOpen(true);
  };

  const handleCloseCampaignSaveTagsModal = async () => {
    setSelectedData([]);
    setCampaignSaveTagsModalOpen(false);
  };

  return (

    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        scroll="paper"
      >
        <CampaignsSaveListModal
          open={campaignsSaveTagsModalOpen}
          onClose={handleCloseCampaignSaveTagsModal}
          data={selectedData}
          aria-labelledby="form-dialog-title"
        />
        <DialogActions>
          <h3>
            {`${i18n.t("campaigns.table.name")} [${campaignId?.name}] #${campaignId?.id}`}
          </h3>

          <Button
            onClick={handleClose}
            color="secondary"
            variant="outlined"
          >
            {i18n.t("campaignsRepostsModal.close")}
          </Button>
        </DialogActions>

        <DialogContent dividers >
          {loading && <TableRowSkeleton columns={4} />}
          {!loading &&
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>

                <Grid item lg={6} xs={12}>
                  <Paper className={classes.customCardChart} elevation={3} >
                    <Grid container spacing={3} >
                      <Grid item className={classes.container} lg={6} xs={6}>
                        <Paper className={classes.customCard} elevation={3} style={{ borderBottom: "2px solid " + doughnutData.datasets[0].backgroundColor[0] }} >
                          <Grid container>
                            <Grid item lg={4} xs={4} className={classes.customCardPercent} >
                              <CircularProgressWithLabel color={doughnutData.datasets[0].backgroundColor[0]} value={sent.length} total={total} style={{ margin: 7 }} />
                            </Grid>
                            <Grid item lg={8} xs={8}>
                              <ListItemText
                                primary={"TOTAL/" + i18n.t("campaignsRepostsModal.sent").toUpperCase()}
                                secondary={
                                  <span className={classes.contactNameWrapper}>
                                    <Typography
                                      className={classes.contactName}
                                      noWrap
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {`${total}/${sent.length}`}
                                    </Typography>
                                    <Button
                                      size="small"
                                      onClick={() => handleOpenCampaignSaveTagsModal(campaignId?.id, "sent", campaignId?.name, i18n.t("campaignsRepostsModal.sent"), sent.length)}
                                      color="primary"
                                      variant="outlined"
                                      className={classes.buttonSave}
                                      startIcon={<Save />}
                                    >
                                      {i18n.t("campaignsModal.buttons.okSave")}
                                    </Button>
                                  </span>
                                } />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid className={classes.container} item lg={6} xs={6}>
                        <Paper className={classes.customCard} elevation={3} style={{ borderBottom: "2px solid " + doughnutData.datasets[0].backgroundColor[1] }} >
                          <Grid container>
                            <Grid item lg={4} xs={4} className={classes.customCardPercent} >
                              <CircularProgressWithLabel color={doughnutData.datasets[0].backgroundColor[1]} value={pending.length} total={total} style={{ margin: 7 }} />
                            </Grid>
                            <Grid item lg={8} xs={8}>
                              <ListItemText
                                primary={i18n.t("campaignsRepostsModal.pending").toUpperCase()}
                                secondary={
                                  <span className={classes.contactNameWrapper}>
                                    <Typography
                                      className={classes.contactName}
                                      noWrap
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {pending.length}
                                    </Typography>
                                    <Button
                                      size="small"
                                      onClick={() => handleOpenCampaignSaveTagsModal(campaignId?.id, "pending", campaignId?.name, i18n.t("campaignsRepostsModal.pending"), pending.length)}
                                      color="primary"
                                      variant="outlined"
                                      className={classes.buttonSave}
                                      startIcon={<Save />}
                                    >
                                      {i18n.t("campaignsModal.buttons.okSave")}
                                    </Button>
                                  </span>
                                } />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid className={classes.container} item lg={6} xs={6}>
                        <Paper className={classes.customCard} elevation={3} style={{ borderBottom: "2px solid " + doughnutData.datasets[0].backgroundColor[2] }} >
                          <Grid container>
                            <Grid item lg={4} xs={4} className={classes.customCardPercent} >
                              <CircularProgressWithLabel color={doughnutData.datasets[0].backgroundColor[2]} value={erro.length} total={total} style={{ margin: 7 }} />
                            </Grid>
                            <Grid item lg={8} xs={8}>
                              <ListItemText primary={i18n.t("campaignsRepostsModal.error").toUpperCase()} secondary={erro.length} />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid className={classes.container} item lg={6} xs={6}>
                        <Paper className={classes.customCard} elevation={3} style={{ borderBottom: "2px solid gray" }} >
                          <Grid container>
                            <Grid item lg={4} xs={4} className={classes.customCardPercent} >
                              <CircularProgressWithLabel color="gray" value={received.length} total={sent.length} style={{ margin: 7 }} />
                            </Grid>
                            <Grid item lg={8} xs={8}>
                              <ListItemText
                                primary={renderMessageAck(1)}
                                secondary={
                                  <span className={classes.contactNameWrapper}>
                                    <Typography
                                      className={classes.contactName}
                                      noWrap
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {received.length}
                                    </Typography>
                                    <Button
                                      size="small"
                                      onClick={() => handleOpenCampaignSaveTagsModal(campaignId?.id, "unread", campaignId?.name, renderMessageAck(2), unread.length)}
                                      color="primary"
                                      variant="outlined"
                                      className={classes.buttonSave}
                                      startIcon={<Save />}
                                    >
                                      {i18n.t("campaignsModal.buttons.okSave")}
                                    </Button>
                                  </span>
                                } />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid className={classes.container} item lg={6} xs={6}>
                        <Paper className={classes.customCard} elevation={3} style={{ borderBottom: "2px solid gray" }} >
                          <Grid container>
                            <Grid item lg={4} xs={4} className={classes.customCardPercent} >
                              <CircularProgressWithLabel color="gray" value={unread.length} total={sent.length} style={{ margin: 7 }} />
                            </Grid>
                            <Grid item lg={8} xs={8}>
                              <ListItemText
                                primary={renderMessageAck(2)}
                                secondary={
                                  <span className={classes.contactNameWrapper}>
                                    <Typography
                                      className={classes.contactName}
                                      noWrap
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {unread.length}
                                    </Typography>
                                    <Button
                                      size="small"
                                      onClick={() => handleOpenCampaignSaveTagsModal(campaignId?.id, "unread", campaignId?.name, renderMessageAck(2), unread.length)}
                                      color="primary"
                                      variant="outlined"
                                      className={classes.buttonSave}
                                      startIcon={<Save />}
                                    >
                                      {i18n.t("campaignsModal.buttons.okSave")}
                                    </Button>
                                  </span>
                                } />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid className={classes.container} item lg={6} xs={6}>
                        <Paper className={classes.customCard} elevation={3} style={{ borderBottom: "2px solid #2196f3" }} >
                          <Grid container>
                            <Grid item lg={4} xs={4} className={classes.customCardPercent} >
                              <CircularProgressWithLabel color="#2196f3" value={read.length} total={sent.length} style={{ margin: 7 }} />
                            </Grid>
                            <Grid item lg={8} xs={8}>
                              <ListItemText
                                primary={renderMessageAck(3)}
                                secondary={
                                  <span className={classes.contactNameWrapper}>
                                    <Typography
                                      className={classes.contactName}
                                      noWrap
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {read.length}
                                    </Typography>
                                    <Button
                                      size="small"
                                      onClick={() => handleOpenCampaignSaveTagsModal(campaignId?.id, "read", campaignId?.name, renderMessageAck(3), read.length)}
                                      color="primary"
                                      variant="outlined"
                                      className={classes.buttonSave}
                                      startIcon={<Save />}
                                    >
                                      {i18n.t("campaignsModal.buttons.okSave")}
                                    </Button>
                                  </span>
                                } />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Typography variant="body1" style={{ margin: "10px", borderBottom: "2px solid blue" }}>
                      {i18n.t("campaignsModal.infoStatusWA")}
                    </Typography>
                  </Paper>

                </Grid>

                <Grid item lg={6} xs={12}>
                  <Paper className={classes.customCardChart} elevation={3}>
                    <Doughnut
                      data={doughnutData}
                      options={doughnutOptions}
                    />
                  </Paper>
                </Grid>

                <Grid item lg={12} xs={12}>

                  <Paper square elevation={3}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="fullWidth"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab
                        style={{ borderBottom: "1px solid " + doughnutData.datasets[0].backgroundColor[0], borderRight: "1px solid " + doughnutData.datasets[0].backgroundColor[0] }}
                        label={
                          <Badge
                            overlap="rectangular"
                            className={classes.badge}
                            badgeContent={sent.length}
                            max={99999}
                            color="secondary"

                          >
                            {i18n.t("campaignsRepostsModal.sent")}
                          </Badge>
                        } />
                      <Tab
                        style={{ borderBottom: "1px solid " + doughnutData.datasets[0].backgroundColor[1], borderRight: "1px solid " + doughnutData.datasets[0].backgroundColor[1] }}
                        label={
                          <Badge
                            overlap="rectangular"
                            className={classes.badge}
                            badgeContent={pending.length}
                            max={99999}
                            color="secondary"
                          >
                            {i18n.t("campaignsRepostsModal.pending")}
                          </Badge>
                        } />
                      <Tab
                        style={{ borderBottom: "1px solid " + doughnutData.datasets[0].backgroundColor[2], borderRight: "1px solid " + doughnutData.datasets[0].backgroundColor[2] }}
                        label={
                          <Badge
                            overlap="rectangular"
                            className={classes.badge}
                            badgeContent={erro.length}
                            max={99999}
                            color="secondary"
                          >
                            {i18n.t("campaignsRepostsModal.error")}
                          </Badge>
                        } />
                      <Tab
                        style={{ borderBottom: "1px solid gray", borderRight: "1px solid gray" }}
                        label={
                          <Badge
                            overlap="rectangular"
                            className={classes.badge}
                            badgeContent={unread.length}
                            max={99999}
                            color="secondary"
                          >
                            {i18n.t("campaignsRepostsModal.unread")}
                          </Badge>
                        } />
                      <Tab
                        style={{ borderBottom: "1px solid #2196f3", borderRight: "1px solid #2196f3" }}
                        label={
                          <Badge
                            overlap="rectangular"
                            className={classes.badge}
                            badgeContent={read.length}
                            max={99999}
                            color="secondary"
                          >
                            {i18n.t("campaignsRepostsModal.read")}
                          </Badge>
                        } />
                    </Tabs>
                    <Paper square style={{ minHeight: "300px", height: "300px", overflowY: "scroll", }}>

                      {value === 0 && (
                        <Table size="small">
                          <TableHead>
                            <TableRow key={0}>
                              <TableCell align="left">{i18n.t("campaignsRepostsModal.name")}</TableCell>
                              <TableCell align="center">{i18n.t("campaignsRepostsModal.number")}</TableCell>
                              <TableCell align="center">Status</TableCell>
                              <TableCell align="center">{i18n.t("campaignsRepostsModal.dateHoraSend")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sent.map((rows) => (
                              <TableRow key={rows.id}>
                                <TableCell>{rows?.contact?.name}</TableCell>
                                <TableCell>{rows?.contact?.number}</TableCell>
                                <TableCell>{renderMessageAck(rows.ack)}</TableCell>
                                <TableCell align="center">{moment(rows.sentAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                      {value === 1 && (
                        <Table size="small">
                          <TableHead>
                            <TableRow key={0}>
                              <TableCell align="left">{i18n.t("campaignsRepostsModal.name")}</TableCell>
                              <TableCell align="center">{i18n.t("campaignsRepostsModal.number")}</TableCell>
                              <TableCell align="center">{i18n.t("campaignsRepostsModal.dateHoraSend")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pending.map((rows) => (
                              <TableRow key={rows.id}>
                                <TableCell>{rows?.contact?.name}</TableCell>
                                <TableCell>{rows?.contact?.number}</TableCell>
                                <TableCell align="center">{moment(rows.sentAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                      {value === 2 && (
                        <Table size="small">
                          <TableHead>
                            <TableRow key={0}>
                              <TableCell align="center">Log/{i18n.t("campaignsRepostsModal.number")}</TableCell>
                              <TableCell align="center">{i18n.t("campaignsRepostsModal.dateHoraChecked")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {erro.map((rows) => (
                              <TableRow key={rows.id}>
                                <TableCell>{rows?.log}</TableCell>
                                <TableCell align="center">{moment(rows.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                      {value === 3 && (
                        <Table size="small">
                          <TableHead>
                            <TableRow key={0}>
                              <TableCell align="left">{i18n.t("campaignsRepostsModal.name")}</TableCell>
                              <TableCell align="center">{i18n.t("campaignsRepostsModal.number")}</TableCell>
                              <TableCell align="center">Status</TableCell>
                              <TableCell align="center">{i18n.t("campaignsRepostsModal.dateHoraSend")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {unread.map((rows) => (
                              <TableRow key={rows.id}>
                                <TableCell>{rows?.contact?.name}</TableCell>
                                <TableCell>{rows?.contact?.number}</TableCell>
                                <TableCell>{renderMessageAck(rows.ack)}</TableCell>
                                <TableCell align="center">{moment(rows.sentAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                      {value === 4 && (
                        <Table size="small">
                          <TableHead>
                            <TableRow key={0}>
                              <TableCell align="left">{i18n.t("campaignsRepostsModal.name")}</TableCell>
                              <TableCell align="center">{i18n.t("campaignsRepostsModal.number")}</TableCell>
                              <TableCell align="center">Status</TableCell>
                              <TableCell align="center">{i18n.t("campaignsRepostsModal.dateHoraSend")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {read.map((rows) => (
                              <TableRow key={rows.id}>
                                <TableCell>{rows?.contact?.name}</TableCell>
                                <TableCell>{rows?.contact?.number}</TableCell>
                                <TableCell>{renderMessageAck(rows.ack)}</TableCell>
                                <TableCell align="center">{moment(rows.sentAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                    </Paper>

                  </Paper>
                </Grid>
              </Grid>
            </Container>
          }
        </DialogContent>
      </Dialog>
    </div >
  )


}

export default CampaignReportsModal