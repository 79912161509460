import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Alert, AlertTitle } from "@material-ui/lab";
import { IconButton, Collapse, Paper, Container } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
    }

}));


const ExpirationNotice = ({ days, due, billingUrl, billingPrice , openDueAlert, handleAlert }) => {

	const classes = useStyles();

	return (

		
		<Collapse in={openDueAlert} >
			<Paper elevation={3} >
			<Alert
				variant="filled"
				severity="error"
				action={
					<IconButton
						aria-label="close"

						color="inherit"
						size="small"
						onClick={() => { handleAlert(false) }}>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				}
			>
				<AlertTitle><strong>AVISO DE VENCIMENTO:</strong></AlertTitle>

				Faltam <strong>{days}</strong> dia(s) para o vencimento (<strong>{due}</strong>) da sua fatura.<br />Após esse período não será possível utilizar o sistema.
			</Alert>

				<div className={classes.root}>
						<Container>
						<h2>Para efetuar o pagamento, clique no link abaixo</h2>
						<p>Para liberar seu sistema mais rapidamente, selecione a opção de <b>PIX.</b></p>
						<p></p>
						<h3>Link para pagamento do valor: R$ {billingPrice} => <a href={billingUrl} title="Link para pagamento" target="_blank">{billingUrl}</a></h3>
						</Container>
				</div>

				{days === 0 &&

						<Container>
						<h2>ATENÇÃO: HOJE É O ÚLTIMO DIA ANTES DO BLOQUEIO TOTAL DO SISTEMA.</h2>
						</Container>

				}

				{days < 0 &&
				
						<Container>
						<h2>ATENÇÃO: O SISTEMA ESTÁ BLOQUEADO POR FALTA DE PAGAMENTO, FAÇA O PAGAMENTO IMEDIATAMENTE.</h2>
						</Container>

				}				
				</Paper>


		</Collapse>
	);
};

export default ExpirationNotice;
