import React, { useState, useEffect, useReducer, useCallback, useContext } from "react";
import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import { LocalOffer, List, Search, PieChart, DeleteOutline, PlayCircleOutline, PauseCircleOutline, Visibility, Help, YouTube } from "@material-ui/icons";

import {
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TextField,
  IconButton,
  Input,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Link
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import CampaignModal from "../../components/CampaignsListModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import moment from 'moment';

import CampaignReportsModal from "../../components/CampaignReportsModal";
import CampaignsViewModal from "../../components/CampaignsView";
import { SettingsContext } from "../../context/Settings/SettingsContext";


// A custom hook that builds on useLocation to parse
// the query string for you.
const getUrlParam = (param) => {
  return (new URLSearchParams(window.location.search)).get(param)
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CAMPAIGNS") {
    const campaigns = action.payload;
    const newCampaigns = [];

    campaigns.forEach((campaigns) => {
      const campaignsIndex = state.findIndex((s) => s.id === campaigns.id);
      if (campaignsIndex !== -1) {
        state[campaignsIndex] = campaigns;
      } else {
        newCampaigns.push(campaigns);
      }
    });

    return [...state, ...newCampaigns];
  }

  if (action.type === "UPDATE_CAMPAIGNS") {
    const campaigns = action.payload;
    const campaignsIndex = state.findIndex((s) => s.id === campaigns.id);

    if (campaignsIndex !== -1) {
      state[campaignsIndex] = campaigns;
      return [...state];
    } else {
      return [campaigns, ...state];
    }
  }

  if (action.type === "DELETE_CAMPAIGNS") {
    const campaignsId = action.payload;

    const campaignsIndex = state.findIndex((s) => s.id === campaignsId);
    if (campaignsIndex !== -1) {
      state.splice(campaignsIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  status: {
    fontSize: "75%",
  },

  statusAlertContent: {
    display: "flex",
    minWidth: "370px"
  },
  statusAlert: {
    width: "90px",
    padding: "6px 6px",
    fontWeight: "400",
    lineHeight: "1.43",
    borderRadius: "4px",
    letterSpacing: "0.01071em",
    marginLeft: "2px",
    marginRigth: "2px",
    fontSize: "0.8em",
    color: "white"
  },
  statusAlertInfo: {
    backgroundColor: theme.palette.info.main
  },
  statusAlertSuccess: {
    backgroundColor: theme.palette.success.main
  },
  statusAlertWarning: {
    backgroundColor: theme.palette.warning.main
  },
  statusAlertDanger: {
    backgroundColor: theme.palette.error.main
  },

  statusAlertInfoColor: {
    color: theme.palette.info.main
  },
  statusAlertSuccessColor: {
    color: theme.palette.success.main
  },
  statusAlertWarningColor: {
    color: theme.palette.warning.main
  },
  statusAlertDangerColor: {
    color: theme.palette.error.main
  },
  textField: {
    maxWidth: "70px",
    maxHeight: "30px"

  }

}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Campaigns = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [deletingCampaign, setDeletingCampaign] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [campaigns, dispatch] = useReducer(reducer, []);

  const [contactId, setContactId] = useState(+getUrlParam('contactId'));
  const [target, setTarget] = useState("list")
  const [anchorEl, setAnchorEl] = useState(null);

  const [campaignsModalOpen, setCampaignModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const [campaignsReportsModalOpen, setCampaignsReportsModalOpen] = useState(false);
  const [selectedCampaignReports, setSelectedCampaignReports] = useState(null);

  const [campaignsViewModalOpen, setCampaignsViewModalOpen] = useState(false);
  const [selectedCampaignView, setSelectedCampaignView] = useState(null);
  
  const { settings } = useContext(SettingsContext);
  const [modulesCampaigns, setmodulesCampaigns] = useState("disabled");

  useEffect(() => {
    const loadsettings = async () => {
      const modulesCampaigns = await settings.find(t => t.key === "modulesCampaigns");
      setmodulesCampaigns(modulesCampaigns?.value);
    }
    loadsettings();
  }, [settings]);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickTarget = (event) => {
    setTarget(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
    handleOpenCampaignModal();
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const fetchCampaigns = useCallback(async () => {

    if (modulesCampaigns === "enabled") {
      try {
        const { data } = await api.get("/campaigns/", {
          params: { searchParam, pageNumber },
        });
        dispatch({ type: "LOAD_CAMPAIGNS", payload: data.campaigns });
        setHasMore(data.hasMore);
        setLoading(false);
      } catch (err) {
        toastError(err);
      }
    }
  }, [searchParam, pageNumber, modulesCampaigns]);

  const handleOpenCampaignModalFromContactId = useCallback(() => {
    if (contactId) {
      handleOpenCampaignModal();
    }
  }, [contactId]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchCampaigns();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, contactId, fetchCampaigns, handleOpenCampaignModalFromContactId]);

  useEffect(() => {
    handleOpenCampaignModalFromContactId();
    const socket = openSocket();

    socket.on("campaigns", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CAMPAIGNS", payload: data.campaigns });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CAMPAIGNS", payload: +data.campaignsId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [handleOpenCampaignModalFromContactId]);

  const cleanContact = () => {
    setContactId("");
  }

  const handleOpenCampaignModal = (e, campaign) => {
    setSelectedCampaign(campaign);
    setCampaignModalOpen(true);
  };

  const handleCloseCampaignModal = async () => {
    setSelectedCampaign(null);
    setCampaignModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenCampaignsReportsModal = (e, campaigns) => {
    if (campaigns) {
      setSelectedCampaignReports(campaigns);
      setCampaignsReportsModalOpen(true);
    }
  };

  const handleCloseCampaignReportsModal = async () => {
    setSelectedCampaignReports(null);
    setCampaignsReportsModalOpen(false);
  };

  const handleDeleteCampaign = async (campaignsId) => {
    try {
      await api.delete(`/campaigns/${campaignsId}`);
      toast.success(i18n.t("campaigns.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingCampaign(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleChangeStatus = async (campaignsId, status) => {
    try {
      await api.put(`/campaigns/${campaignsId}`, {
        status,
      });
      toast.success(i18n.t("campaigns.status.changeStatusSuccess") + " '" + i18n.t(`campaigns.status.${status}`) + "'");
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangeDelay = async (campaignsId, delay) => {
    try {
      await api.put(`/campaigns/${campaignsId}`, {
        delay: delay,
      });

      toast.success(i18n.t("campaigns.status.changeDelay"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenCampaignsViewModal = (e, campaigns) => {
    if (campaigns) {
      setSelectedCampaignView(campaigns);
      setCampaignsViewModalOpen(true);
    }
  };

  const handleCloseCampaignsViewModal = async () => {
    setSelectedCampaignView(null);
    setCampaignsViewModalOpen(false);
  };

  return (
    <MainContainer>
      {modulesCampaigns !== "enabled"
        ?
        <MainHeader>
          <Title>{i18n.t("campaigns.title")} - {i18n.t("super.settings.modulesCampaigns.options.nothing")}</Title>
        </MainHeader>
        :
        <>
          <ConfirmationModal
            title={
              deletingCampaign &&
              `${i18n.t("campaigns.confirmationModal.deleteTitle")}`
            }
            open={confirmModalOpen}
            onClose={setConfirmModalOpen}
            onConfirm={() => handleDeleteCampaign(deletingCampaign.id)}
          >
            {i18n.t("campaigns.confirmationModal.deleteMessage")}
          </ConfirmationModal>
          <CampaignModal
            open={campaignsModalOpen}
            onClose={handleCloseCampaignModal}
            reload={fetchCampaigns}
            target={target}
            aria-labelledby="form-dialog-title"
            campaignId={selectedCampaign && selectedCampaign.id}
            contactId={contactId}
            cleanContact={cleanContact}
          />
          <CampaignReportsModal
            open={campaignsReportsModalOpen}
            onClose={handleCloseCampaignReportsModal}
            reload={fetchCampaigns}
            aria-labelledby="form-dialog-title"
            campaignId={selectedCampaignReports}
          />
          <CampaignsViewModal
            open={campaignsViewModalOpen}
            onClose={handleCloseCampaignsViewModal}
            aria-labelledby="form-dialog-title"
            campaignId={selectedCampaignView}
          />

          <MainHeader>
            <Title>{i18n.t("campaigns.title")}</Title>
            <Link target="_blank" href="https://youtu.be/nfVgQxV_XSg">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ marginLeft: "15px" }}
              >
                Assista o vídeo <Help fontSize="small" />
              </Button>
            </Link>
            <MainHeaderButtonsWrapper>


              <TextField
                placeholder={i18n.t("contacts.searchPlaceholder")}
                type="search"
                value={searchParam}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClickMenu}
              >
                {i18n.t("campaigns.buttons.add")}
              </Button>
              <StyledMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <StyledMenuItem style={{ backgroundColor: "#ffffff" }}>
                  <span >Escolha uma opção</span>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleClickTarget} id="tags">
                  <ListItemIcon>
                    <LocalOffer />
                  </ListItemIcon>
                  <ListItemText primary="Selecionar Etiquetas/Tags" secondary="Escolha uma ou mais Etiquetas/Tags" />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleClickTarget} id="list">
                  <ListItemIcon>
                    <List />
                  </ListItemIcon>
                  <ListItemText primary="Colar números" secondary="Cole os números um abaixo do outro" />
                </StyledMenuItem>
              </StyledMenu>

            </MainHeaderButtonsWrapper>
          </MainHeader>
          <Paper
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">{i18n.t("campaigns.table.name")}</TableCell>
                  <TableCell align="left">{i18n.t("campaigns.table.sendAt")}</TableCell>
                  <TableCell align="left">{i18n.t("campaigns.table.user")}</TableCell>
                  <TableCell align="center">{i18n.t("campaigns.table.analytics")}</TableCell>
                  <TableCell align="center">{i18n.t("campaigns.table.status")}</TableCell>
                  <TableCell align="center">{i18n.t("campaigns.table.delay")}</TableCell>
                  <TableCell align="center">{i18n.t("campaigns.table.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaigns.map((campaigns) => (
                  <TableRow key={campaigns.id} >
                    <TableCell align="left">{campaigns.name}</TableCell>
                    <TableCell align="left">{moment(campaigns.scheduleDate).format('DD/MM/YY HH:mm')}</TableCell>
                    <TableCell align="left">{campaigns.user?.name}</TableCell>
                    <TableCell align="left" >
                      <div className={`${classes.statusAlertContent}`}>
                        <Box className={`${classes.statusAlert} ${classes.statusAlertInfo}`} >
                          Total: {campaigns.countTotal}
                        </Box>
                        <Box className={`${classes.statusAlert} ${classes.statusAlertSuccess}`} >
                          Enviados: {campaigns.countSent}
                        </Box>
                        <Box className={`${classes.statusAlert} ${classes.statusAlertWarning}`} >
                          Pendente: {campaigns.countPending}
                        </Box>
                        <Box className={`${classes.statusAlert} ${classes.statusAlertDanger}`} >
                          Erro: {campaigns.countError}
                        </Box>
                      </div>
                    </TableCell>
                    <TableCell align="center"  >

                      <Box
                        variant="contained"
                        className={`${classes.statusAlert} ${campaigns.status === "pending" ? classes.statusAlertWarning : campaigns.status === "paused" ? classes.statusAlertDanger : classes.statusAlertSuccess}`}
                        size="small"

                      >
                        {i18n.t(`campaigns.status.${campaigns.status}.name`)}
                      </Box>

                    </TableCell>
                    <TableCell align="center"  >

                      <Input
                        className={classes.input}
                        value={campaigns.delay}
                        margin="dense"
                        onChange={(e) => handleChangeDelay(campaigns.id, e.target.value)}
                        // onBlur={(e) => handleBlur(e.target.value)}
                        inputProps={{
                          step: 1,
                          min: 3,
                          max: 100,
                          type: 'number',
                          'aria-labelledby': 'input-slider',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {campaigns.status === "pending" &&
                        <IconButton
                          title={i18n.t(`campaigns.status.${campaigns.status}.buttonTitle`)}
                          size="small"
                          className={`${campaigns.status === "pending" ? classes.statusAlertWarningColor : campaigns.status === "paused" ? classes.statusAlertDangerColor : classes.statusAlertSuccessColor}`}
                          onClick={() => handleChangeStatus(campaigns.id, "paused")}
                        >
                          <PauseCircleOutline />
                        </IconButton>
                      }
                      {campaigns.status === "paused" &&
                        <IconButton
                          title={i18n.t(`campaigns.status.${campaigns.status}.buttonTitle`)}
                          size="small"
                          className={`${campaigns.status === "pending" ? classes.statusAlertWarningColor : campaigns.status === "paused" ? classes.statusAlertDangerColor : classes.statusAlertSuccessColor}`}
                          onClick={() => handleChangeStatus(campaigns.id, "pending")}
                        >
                          <PlayCircleOutline />
                        </IconButton>
                      }
                      <IconButton
                        title={i18n.t("campaigns.buttons.report")}
                        size="small"
                        onClick={(e) => handleOpenCampaignsReportsModal(e, campaigns)}
                      >
                        <PieChart />
                      </IconButton>
                      <IconButton
                        title={i18n.t("campaigns.buttons.view")}
                        size="small"
                        onClick={(e) => handleOpenCampaignsViewModal(e, campaigns)}
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton
                        title={i18n.t("campaigns.buttons.delete")}
                        size="small"
                        onClick={(e) => {
                          setConfirmModalOpen(true);
                          setDeletingCampaign(campaigns);
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={4} />}
              </TableBody>
            </Table>
          </Paper>
        </>
      }
    </MainContainer>
  );
};

export default Campaigns;
