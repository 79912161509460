import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { IconButton, Paper, Tooltip } from "@material-ui/core";


import { Edit, AddCircle } from '@material-ui/icons';

import moment from 'moment';
import clsx from "clsx";

import {
    DeleteOutline,
    CalendarToday,
    CheckBoxOutlineBlank,
    Sync,

} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import { Alert } from "@material-ui/lab";

import api from "../../services/api";

import toastError from "../../errors/toastError";
import LancherBoxModal from "../LancherBoxModal";

import ConfirmationModal from "../../components/ConfirmationModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";

const useStyles = makeStyles(theme => ({

    contactAvatar: {
        margin: 15,
        width: 160,
        height: 160,
    },

    contactHeader: {
        display: "flex",
        padding: 8,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: 4,
        },
    },

    contactDetails: {
        marginTop: 8,
        padding: 8,
        display: "flex",
        flexDirection: "column",
    },
    contactExtraInfo: {
        marginTop: 4,
        padding: 6,
    },
    copy: {
        marginLeft: 2,
        cursor: "pointer",
    },
    root: {
        marginBottom: 5,
    },
    title: {
        display: "flex",
        // fontSize: 13,
        // textShadow: '1px 1px 1px black',
        fontWeight: 500,
        color: 'black',
        textAlign: 'center',
    },
    pos: {
        marginBottom: 12,
    },

    infoTag: {
        flex: 1,
        display: "flex",
        color: "gray",
        paddingTop: 3,
        paddingLeft: 5,
        marginRight: 1,
        borderRadius: 3,
    },
    infoTagMany: {
        fontSize: 13,
        borderBottom: "1px solid #e9e9e9",
        display: "flex",
        color: "gray",
        // paddingBotton: 3,
        flexFlow: "row nowrap",
        justifySelf: "center",
        alignSelf: "center",
    },
    infoTagExpire72: {
        color: "#FEDE00",
    },
    infoTagExpire24: {
        color: "#FF8C00",
    },
    infoTagExpired: {
        color: "red",
    },
    infoTagBlue: {
        color: "RoyalBlue",
    },
    infoTagGreen: {
        color: "#00a859",
    },
    infoButton: {
        paddingRight: 5,
        cursor: "pointer",
    },
}));



const ContactDrawerLauncherBoxCards = ({ subs }) => {
    const classes = useStyles();
    const [launchersData, setLaunchersData] = useState({});
    const [selectedLauncherBox, setSelectedLauncherBox] = useState(null);
    const [selectedLBSubscription, setSelectedLBSubscription] = useState(null);
    const [launcherBoxModalOpen, setLauncherBoxModalOpen] = useState(false);

    const [deletingModalOpen, setDeletingModalOpen] = useState(false);
    const [deleting, setDeleting] = useState(null);
    const [loading, setLoading] = useState(false);

    const expireHours = (a) => {
        const b = moment(a).diff(moment(), 'hours')
        return b;
    }

    const handleOpenLauncheBoxModal = (subscriptionId) => {
        setSelectedLauncherBox(null);
        setSelectedLBSubscription(subscriptionId)
        setLauncherBoxModalOpen(true);
    };

    const handleCloseLauncheBoxModal = () => {
        fetchLauncher()
        setSelectedLauncherBox(null);
        setSelectedLBSubscription(null)
        setLauncherBoxModalOpen(false);
    };

    const handleEditLauncheBoxModal = (subscriptionId, sLauncherBox) => {
        setSelectedLauncherBox(sLauncherBox);
        setSelectedLBSubscription(subscriptionId)
        setLauncherBoxModalOpen(true);
    };


    const fetchLauncher = async () => {
        setLoading("Carregando...");
        try {
            const { data } = await api.get(`/launcherBox/ListLaucherBoxBySubscriptionId/${subs}`);
            await data?.map(async item => {

                if (item?.sLauncherBox) { await handleSyncLauncherBox(item.sLauncherBox) }
            })

            setLaunchersData(data);
            setLoading(false);

        } catch (err) {
            setLoading(false);
            toastError(err);
        }

    };


    const handleSyncLauncherBoxUpdateDue = async (id) => {
        setLoading("Sincronizando datas...");
        try {
            await api.get(`/webhooks/syncLauncherBoxUpdateDue/${id}`);
            const { data } = await api.get(`/launcherBox/ListLaucherBoxBySubscriptionId/${subs}`);

            setLaunchersData(data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }

    };

    const handleSyncLauncherBox = async (id) => {
        setLoading("Sincronizando...");
        try {
            await api.get(`/webhooks/syncLauncherBox/${id}`);
            const { data } = await api.get(`/launcherBox/ListLaucherBoxBySubscriptionId/${subs}`);

            setLaunchersData(data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }

    };

    useEffect(() => {

        fetchLauncher();
        // eslint-disable-next-line
    }, [subs]);


    const handleDelete = async (launcherBoxId) => {
        setLoading("Excluindo...");
        try {
            await api.delete(`/launcherBox/${launcherBoxId}`);
            fetchLauncher();
            toast.success(i18n.t("global.toasts.deleted"));
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
        setDeleting(null);
    };

    const handleSetDelete = async (name, deletingId) => {
        setDeletingModalOpen(true);
        setDeleting({
            name,
            deletingId
        })

    };

    return (
        <>

            <div className={classes.root}>
                <ConfirmationModal
                    title={
                        deleting &&
                        `${i18n.t("global.deleteTitle")} ${deleting.name}?`
                    }
                    open={deletingModalOpen}
                    onClose={setDeletingModalOpen}
                    onConfirm={() => handleDelete(deleting.deletingId)}
                >
                    {i18n.t("global.deleteMessage")}
                </ConfirmationModal>
                <LancherBoxModal
                    open={launcherBoxModalOpen}
                    onClose={handleCloseLauncheBoxModal}
                    aria-labelledby="form-dialog-title"
                    subscriptionId={selectedLBSubscription}
                    launcherBoxId={selectedLauncherBox}

                ></LancherBoxModal>
                <Paper className={`${classes.title}`} variant="outlined" elevation={3} style={{ margin: 5, padding: 5, flex: 1 }}>
                    <CheckBoxOutlineBlank className={`${classes.infoButton} ${classes.infoTagBlue}`} />LAUNCHERBOX

                    <IconButton
                        size="small"
                        onClick={() => handleOpenLauncheBoxModal(subs)}
                    >
                        <AddCircle color="primary" alt={i18n.t("global.buttons.new")} />
                    </IconButton>
                </Paper>
                {loading && <><TableRowSkeleton columns={2} /> {loading}</>}
                {launchersData?.length > 0 && launchersData?.map(launcher => (
                    <Paper variant="outlined" elevation={3} style={{ margin: 5, padding: 5, flex: 1 }}>

                        {launcher.sLauncherBox &&
                            <>
                                <div className={classes.infoTagMany}>
                                    <CheckBoxOutlineBlank className={`${classes.infoButton} ${classes.infoTagGreen}`} /><span style={{ fontSize: 16, fontWeight: 500 }}>{launcher.sLauncherBox}</span>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleSyncLauncherBox(launcher.sLauncherBox)}
                                    >
                                        <Tooltip title={i18n.t("global.buttons.actualize")} >
                                            <Sync color="primary" />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleEditLauncheBoxModal(subs, launcher.id)}
                                    >
                                        <Tooltip title={i18n.t("global.buttons.edit")} >
                                            <Edit color="primary" />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            handleSetDelete(
                                                launcher?.sLauncherBox,
                                                launcher.id
                                            );
                                        }}
                                    >
                                        <Tooltip title={i18n.t("global.buttons.delete")} >
                                            <DeleteOutline color="secondary" />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleSyncLauncherBoxUpdateDue(launcher.id)}
                                    >
                                        <Tooltip title={i18n.t("global.buttons.actualize")} >
                                            <ImportExportIcon color="secondary" />
                                        </Tooltip>
                                    </IconButton>

                                </div>
                                <div className={classes.infoTagMany}>
                                    <CalendarToday className={`${classes.infoButton} ${classes.infoTagGreen}`} /><strong>{i18n.t("global.activedAt")}:</strong>&nbsp;<span>{launcher.sLBActivatedDate ? moment(launcher.sLBActivatedDate).format('DD/MM/YY HH:mm') : ""}</span>
                                </div>
                                <div
                                    className={clsx(`${classes.infoTagMany}`, {
                                        [classes.infoTagGreen]: expireHours(launcher.sLBDueDate) > 72,
                                        [classes.infoTagExpire72]: expireHours(launcher.sLBDueDate) <= 72,
                                        [classes.infoTagExpire24]: expireHours(launcher.sLBDueDate) <= 24,
                                        [classes.infoTagExpired]: expireHours(launcher.sLBDueDate) <= 0,
                                    })}
                                >
                                    <CalendarToday className={`${classes.infoButton} ${classes.infoTagGreen}`} /><span><strong>{i18n.t("global.expiresAt")}: &nbsp;{launcher.sLBDueDate ? moment(launcher.sLBDueDate).format('DD/MM/YY HH:mm') : ""}</strong></span>
                                </div>
                                <div
                                    className={clsx(`${classes.infoTagMany}`, {
                                        [classes.infoTagExpire72]: expireHours(launcher.sLBValidityCredit) <= 720,
                                        [classes.infoTagExpire24]: expireHours(launcher.sLBValidityCredit) <= 24,
                                        [classes.infoTagExpired]: expireHours(launcher.sLBValidityCredit) <= 0,
                                    })}
                                >
                                    <CalendarToday className={`${classes.infoButton} ${classes.infoTagGreen}`} /> <strong>{i18n.t("global.creditValidity")}:</strong>&nbsp;<span>{launcher.sLBValidityCredit ? moment(launcher.sLBValidityCredit).format('DD/MM/YY HH:mm') : ""}</span>
                                </div>
                                <div className={classes.infoTagMany}>
                                    <CalendarToday className={`${classes.infoButton} ${classes.infoTagBlue}`} /> <strong>{i18n.t("global.lastAccess")}:</strong>&nbsp;<span>{launcher.sLBLastAccess ? moment(launcher.sLBLastAccess).format('DD/MM/YY HH:mm') : ""}</span>
                                </div>


                                {moment(launcher.sLBValidityCredit).diff(launcher.sLBDueDate, 'hours') <= 0 &&
                                <Alert severity="error">
                                    A data de vencimento do crédito da <strong>launcherBox</strong> expira antes do vencimento da assinatura.
                                </Alert>
                                }
                                <div style={{ textAlign: "center", flex: 1, fontSize: "0.7em", marginTop: 5, color: "gray" }}>{i18n.t("global.updatedAt")}: {launcher.sLLastUpdateSync ? moment(launcher.sLLastUpdateSync).format('DD/MM/YY HH:mm') : ""}</div>
                            </>
                        }

                    </Paper>
                ))}

            </div >

        </>
    )
};
export default ContactDrawerLauncherBoxCards;