const rules = {
	user: {
		static: [
			//Tickets
			"ticket-options:transferWhatsapp",
			//View Menu
			"drawer-items:tickets",
		],
	},

	supervisor: {
		static: [
			//Tickets
			"tickets-manager:showall",
			"ticket-options:transferWhatsapp",
			//User Modal
			"user-modal:editQueues",
			//View Menu
			"drawer-items:tickets",
			"drawer-items:management",
			"drawer-items:supervisor",
			
		],
	},

	finance: {
		static: [
			//View Menu
			"drawer-items:management",
			"drawer-items:finance",
		],
	},

	dashboard: {
		static: [
			//View Menu
			"drawer-items:management",
			"drawer-items:dashboard",
		],
	},

	admin: {
		static: [
			//Connections
			"connections:delete",
			//Contacts
			"contacts-page:deleteContact",
			//Settings
			"settings-dev-items:view",
			"settings-super-items:view",
			//Tickets
			"tickets-manager:showall",
			"ticket-options:transferWhatsapp",
			"ticket-options:deleteTicket",
			//User Modal
			"user-modal:editProfile",
			"user-modal:editQueues",
			//View Menu
			"drawer-items:tickets",
			"drawer-items:management",
			"drawer-items:dashboard",
			"drawer-items:finance",
			"drawer-items:supervisor",
			"drawer-items:admin",
		],
	},

	super: {
		static: [
			//Connections
			"connections:delete",
			//Contacts
			"contacts-page:deleteContact",
			//Settings
			"settings-dev-items:view",
			"settings-super-items:view",
			"settings-super-items:edit",
			//Tickets
			"tickets-manager:showall",
			"ticket-options:transferWhatsapp",
			"ticket-options:deleteTicket",
			//User Modal
			"user-modal:editProfile",
			"user-modal:editQueues",
			//View Menu
			"drawer-items:tickets",
			"drawer-items:management",
			"drawer-items:dashboard",
			"drawer-items:finance",
			"drawer-items:supervisor",
			"drawer-items:admin",
			"drawer-items:super",
		]
	}
};

export default rules;
