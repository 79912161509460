import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";

import MainListItems from "./MainListItems";
// import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import ExpirationNotice from "../components/ExpirationNotice";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import logo from "../assets/logo.png";
import { SettingsContext } from "../context/Settings/SettingsContext";

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    // Retirado para melhor visualização da lista de ticket
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 56px)",
    // },
  },
  toolbar: {
    paddingRight: 24,
    color: "#FFFFFF",
    background: `linear-gradient(to right, ${theme.palette.primary.main},  ${theme.palette.primary.light})`
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "48px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 5,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    boxShadow: "0 0 6px 0 gray",
    position: "relative",
    whiteSpace: "nowrap",
    height: "100%",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    minHeight: "48px",
  },
  content: {
    flex: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  userName: {
    fontSize: "75%",
    paddingLeft: "5px",
  },
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);
  const [clientName, setClientName] = useState(null);

  const [days, setDays] = useState(0);
  const [due, setDue] = useState(0);
  const [openDueAlert, setOpenDueAlert] = useState(false);
  const [billingUrl, setBillingUrl] = useState("");
  const [billingPrice, setBillingPrice] = useState("");
  const { settings } = useContext(SettingsContext);

  useEffect(() => {
    const loadsettings = async () => {
      const dueDate = await settings.find(t => t.key === "dueDate");

      const billingUrl = await settings.find(t => t.key === "billingUrl");
      setBillingUrl(billingUrl?.value);
      const billingPrice = await settings.find(t => t.key === "billingPrice");
      setBillingPrice(billingPrice?.value);

      const second = 1000;
      const minute = second * 60;
      const hour = minute * 60;
      const day = hour * 24;

      let date_ini = new Date();
      let date_end = new Date(dueDate?.value);

      let diff = date_end.getTime() - date_ini.getTime();

      setDue(date_end.toLocaleDateString('pt-br'));
      setDays(Math.floor(diff / day) + 1);
      if ((Math.floor(diff / day) + 1) < 6) setOpenDueAlert(true);

      setOpenDueAlert(true);

    }
    loadsettings();
  }, [settings]);

  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      setDrawerOpen(true);
    }

    let getClientName = window.location.hostname;
    getClientName = getClientName.split(".");
    getClientName = getClientName[0];
    setClientName(getClientName);

  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };

  function handleOpenDueAlert() {
    if (days >= 0) {
      setOpenDueAlert(false);
    }
  }

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>

      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >

        <div className={classes.toolbarIcon}>
          <img alt="send4whats.com" src={logo} height={"48"} style={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex' }} />
          {drawerVariant === "temporary" &&
            <IconButton >
              <ChevronLeftIcon onClick={() => setDrawerOpen(!drawerOpen)} />
            </IconButton>
          }
        </div>
        <List>
          <MainListItems drawerClose={drawerClose} />
        </List>
        <Divider />
      </Drawer>

      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color={process.env.NODE_ENV === "development" ? "inherit" : "primary"}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {clientName}
          </Typography>

          {/* {user.id && <NotificationsPopOver />} */}

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
              <Typography
                component="h5"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.userName}
              >
                {user.name}
              </Typography>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      
      <main className={classes.content}>
        
        <div className={classes.appBarSpacer} />
        {days < 6 &&
        <ExpirationNotice days={days} due={due} billingUrl={billingUrl} billingPrice={billingPrice} openDueAlert={openDueAlert} handleAlert={handleOpenDueAlert} />
        }
        {
          days >= 0 || user.profile === "super" 
          ? children ? children : null 
          : null
        }
      </main>
    </div>
  );
};

export default LoggedInLayout;
