import React, { useContext, useState } from "react";

import { toast } from "react-toastify";
import { green } from "@material-ui/core/colors";

import {
	Button,
	makeStyles,
	Paper,
	Select,
	Tooltip,
	Typography,
	Container,
	IconButton,
	FormControl,
	Input,
	InputAdornment,
	Grid
} from "@material-ui/core";

import SaveIcon from "@material-ui/icons/Save";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import packageJson from '../../../package.json';
import { SettingsContext } from "../../context/Settings/SettingsContext";

const useStyles = makeStyles(theme => ({
	root: {
		root: {
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				flexWrap: "wrap",
			},
			[theme.breakpoints.up("sm")]: {
				maxWidth: "600px",
				flexWrap: "wrap",
			},
		},
		container: {
			padding: "16px 24px",
			overflow: 'auto',
			border: "2px solid red"
		},
		mainPaper: {
			margin: "0 auto",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
			[theme.breakpoints.up("sm")]: {
				minWidth: "600px",
			},
		},
		title: {
		},
		titleH2: {
			fontSize: "1.25rem",
			fontWeight: "500",
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
		},
		btnWrapper: {
			position: "relative",
		},
		buttonProgress: {
			color: green[500],
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		textCampaignsContainer: {
			width: "100%",
		},
		content: {
			padding: "0 24px 16px",
		},
		formActions: {
			flex: "0 0 auto",
			display: "flex",
			padding: "8px",
			alignItems: "center",
			justifyContent: "flex-end",
		},
		option: {
			display: "inline-flex",
			width: "100%",
			margin: "8px 0",
		},
		settingLabel: {
			padding: "5px 0",
		},
		settingOption: {
			marginLeft: "auto",
			minWidth: "128px",
		},
		settingTextField: {
			margin: "0 0 0 auto",
			maxWidth: "109px",
		},
		divider: {
			margin: "16px 0",
			border: "none",
			height: "1px",
			width: "100%",
			backgroundColor: "rgba(0, 0, 0, 0.12)",
		},
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,
		paddingTop: 25,

	},
	paper2: {
		padding: theme.spacing(2),
		alignItems: "center",
		marginBottom: 12,

	},
	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},

	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: '25ch',
	},
}));

const FormSettings = ({ name, text }) => {

	const classes = useStyles();
	const [settingsText, setSettingsText] = useState(text);

	const handleChangeSettingWithText = async (e) => {

		const settingKey = e.replace("Text", "");
		const textValue = document.getElementById(e).value;

		try {
			await api.put(`/settings/${settingKey}`, {
				text: textValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}

	};

	const handleChangeThis = async e => {
		const selectedValue = e.target.value;
		setSettingsText(selectedValue)

	};


	return (
		<FormControl fullWidth className={classes.margin} >
			<Input
				id={name + "Text"}
				name={name + "Text"}
				type='text'
				onChange={handleChangeThis}
				value={settingsText}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label={name + "Text"}
							onClick={() => handleChangeSettingWithText(`${name}Text`)}
						>
							<SaveIcon />
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	);
};

const Settings = () => {

	const classes = useStyles();
	const { settings } = useContext(SettingsContext);

	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};


	const getSettingValue = key => {
		try {
			const { value } = settings.find(s => s.key === key);
			return value;
		} catch (error) {
			return 0;

		}
	};
	const getSettingText = key => {
		try {
			const { text } = settings.find(s => s.key === key);
			return text;
		} catch (error) {
			return 0;

		}
	};

	const goToSupport = () => {
		window.open(packageJson.systemVars.support.whatsapp, '_blank');
	}

	const handleCreateSetting = async (e) => {

		const settingKey = e;

		try {
			await api.post(`/settings`, {
				key: settingKey,
				value: "enabled",
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const SettingsOptions = (props) => {
		return (
			<>
				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={i18n.t(`settings.settings.${props.settingsName}.note`)}>
					<Paper className={classes.paper} elevation={3}>
						<Grid container spacing={3}>
							<Grid item xs={8} style={{ textAlign: 'left', }}>
								<Typography variant="body1">
									{i18n.t(`settings.settings.${props.settingsName}.name`)}
								</Typography>
							</Grid>
							<Grid item xs={4} style={{ textAlign: 'right', }}>
								{getSettingValue(props.settingsName) === 0 ?

									<Button size="small" color="primary" variant="outlined" onClick={e => handleCreateSetting(props.settingsName)} >{i18n.t(`settings.activate`)} </Button>
									:
									<Select
										margin="dense"
										variant="outlined"
										native
										id={props.settingsName + "-setting"}
										name={props.settingsName}
										value={
											settings && settings.length > 0 && getSettingValue(props.settingsName)
										}
										className={classes.settingOption}
										onChange={handleChangeSetting}
									>
										{!props.options &&
											<>
												<option value="disabled">
													{i18n.t(`settings.settings.${props.settingsName}.options.disabled`)}
												</option>
												<option value="enabled">
													{i18n.t(`settings.settings.${props.settingsName}.options.enabled`)}
												</option>
											</>
										}
										{props.options &&

											props.options?.map((o) => (
												<option key={o} value={o}>
													{i18n.t(`settings.settings.${props.settingsName}.options.${o}`)}
												</option>
											))

										}
									</Select>
								}
							</Grid>

							{props.message &&
								<Grid item xs={12}>
									<FormSettings name={props.settingsName} value={getSettingValue(props.settingsName)} text={getSettingText(props.settingsName)} />
										
								</Grid>
							}

						</Grid>
					</Paper>
				</Tooltip>
			</>
		)
	}

	return (
		<MainContainer>
			<MainHeader>
				<Title>{i18n.t("settings.title")}</Title>
				<MainHeaderButtonsWrapper>
					<Button
						variant="contained"
						color="primary"
						onClick={goToSupport}
					// disabled
					>
						{i18n.t("settings.support")}
					</Button>
				</MainHeaderButtonsWrapper>
			</MainHeader>
			<Paper
				className={classes.mainPaper}
				variant="outlined"
				style={{ overflow: "auto", with: "100%", maxHeight: "100%", paddingTop: "25px", paddingBottom: "25px" }}
			>
				<div className={classes.root}>
					<Container className={classes.container} maxWidth="md" >
						<SettingsOptions
							settingsName="hoursCloseTicketAuto"
							options={[0, 1, 2, 4, 6, 12, 24, 36, 48, 60, 72]}
						/>
						<SettingsOptions
							settingsName="timeCreateNewTicket"
							options={[10, 30, 60, 300, 1800, 3600, 7200, 21600, 43200, 86400, 172800]}
						/>
						<SettingsOptions settingsName="call" />
						<SettingsOptions settingsName="CheckMsgIsGroup" />
						<SettingsOptions settingsName="queuedTicketsOnly" />
						<SettingsOptions settingsName="ticketWaitingNoShowMessagem" />
						<SettingsOptions settingsName="sendMessageToSelectQueue" message={"sendMessageToSelectQueueText"} />
						<SettingsOptions settingsName="userSpyTicket" />
						<SettingsOptions settingsName="showSignMessage" />

						{getSettingValue("modulesStreaming") && 
						<>
							<SettingsOptions settingsName="launcherBox" message={"sendMessageToSelectQueueText"} />
							<SettingsOptions settingsName="painelTVS" message={"sendMessageToSelectQueueText"} />
						</>
						}
						
					</Container>
				</div>

			</Paper>

		</MainContainer>
	);
};

export default Settings;
