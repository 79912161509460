import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { FormControl, CircularProgress, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Paper } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import { Autocomplete, Alert, AlertTitle } from "@material-ui/lab";

import { isArray } from "lodash";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	paper: {
		marginTop: 20,
		padding: 20,
	},
}));

const CampaignsSchema = Yup.object().shape({
	// tag: Yup.string()
	//  	.min(3, "Mensagem muito curta")
	//  	.required("Obrigatório")
});

const CampaignsSaveListModal = ({ open, onClose, data }) => {
	const classes = useStyles();

	const initialState = {
		campaignId: data.id,
		status: data.status,
		tag: ""
	};

	const initialContact = {
		id: "",
		name: ""
	}

	const [tagsData, setTagsData] = useState(initialState);
	const [tags, setTags] = useState([initialContact]);
	const [dataToSave, setDataToSave] = useState(initialState);

	useEffect(() => {
		setTagsData({
			campaignId: data.id,
			status: data.status,
			tag: ""
		});
		setDataToSave(data);
		try {
			(async () => {

				const { data } = await api.get('/tags/list');
				let customList = data.map((c) => ({ id: c.id, name: c.name }));
				if (isArray(customList)) {
					setTags([{ id: "", name: "" }, ...customList]);
				}

			})()
		} catch (err) {
			toastError(err);
		}
	}, [data, open]);

	const handleClose = () => {
		onClose();
		setTagsData(initialState);
	};

	const handleSaveContactsTag = async values => {
		try {
			if (values.tag) {
				await api.post("/campaignsSends/tags", values);
				toast.success(i18n.t("campaignsRepostsSaveListModal.success"));
			}
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{i18n.t("campaignsModal.buttons.okSave")} {dataToSave.infoStatus}
				</DialogTitle>
				<Formik
					initialValues={tagsData}
					enableReinitialize={true}
					validationSchema={CampaignsSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContactsTag(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>

								<FormControl
									variant="outlined"
									fullWidth
								>
									<Autocomplete
										freeSolo
										fullWidth
										options={tags}
										onChange={(e, tag) => {
											const tags = tag ? tag : '';
											setTagsData({ ...tagsData, tag: tags.name });
										}}
										onInputChange={async (event, value, reason) => {

											if (reason === 'input') {
												setTagsData({ ...tagsData, tag: value });
											}
										}}
										getOptionLabel={(option) => option.name}
										getOptionSelected={(option, value) => {
											return value.id === option.id
										}}
										renderInput={(params) => <TextField {...params} variant="outlined" placeholder={i18n.t("campaignsRepostsSaveListModal.tagName")} />}
									/>
								</FormControl>

								<Paper className={classes.paper} elevation={3} >
									<Alert severity="info">
										<AlertTitle>INFO</AlertTitle>
										{i18n.t("campaignsRepostsSaveListModal.info").replace("##", `'${dataToSave.infoStatus}'`)}
									</Alert>
								</Paper>

								<Paper className={classes.paper} elevation={3} >
									<Box>
										<span style={{ fontWeight: "600" }}>{i18n.t("campaigns.table.name")}:</span> {dataToSave.infoName} #{dataToSave.id}
									</Box>
									<Box>
										<span style={{ fontWeight: "600" }}>{i18n.t("campaigns.table.status")}:</span> {dataToSave.infoStatus}
									</Box>
									<Box>
										<span style={{ fontWeight: "600" }}>{dataToSave.infoStatus}:</span> {dataToSave.infoCount}
									</Box>
								</Paper>



							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("campaignsModal.buttons.cancel")}
								</Button>
								{/* {(campaigns.sentAt === null || campaigns.sentAt === "") && ( */}
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting} //|| !campaigns.tag
									variant="contained"
									className={classes.btnWrapper}
								>
									{i18n.t("campaignsModal.buttons.okSave")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
								{/* )} */}
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default CampaignsSaveListModal;
