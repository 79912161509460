import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import Chip from "@material-ui/core/Chip";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const TicketsQueueSelect = ({
	userQueues,
	selectedQueueIds = [],
	onChange,
}) => {
	const classes = useStyles();
	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div style={{ marginTop: -4 }}>

			<form>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>{i18n.t("queueSelect.inputLabel")}</InputLabel>

					<Select
						multiple
						labelWidth={60}
						value={selectedQueueIds}
						onChange={handleChange}
						MenuProps={{
							anchorOrigin: {
								vertical: "bottom",
								horizontal: "left",
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "left",
							},
							getContentAnchorEl: null,
						}}
						renderValue={selected => (
							<div className={classes.chips}>
								{selected?.length > 0 &&
									selected.map(id => {
										const queue = userQueues.find(q => q.id === id);
										return queue ? (
											<Chip
												key={id}
												style={{ backgroundColor: queue.color }}
												variant="outlined"
												label={queue.name}
												size="small"
												className={classes.chip}
											/>
										) : null;
									})}
							</div>
						)}
					>

						{userQueues.map(queue => (
							<MenuItem key={queue.id} value={queue.id}>
								{queue.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</form>


		</div>
	);
};

export default TicketsQueueSelect;
