import React, { useEffect, useState, useContext } from "react";
import ClipboardJS from "clipboard";

import { makeStyles } from "@material-ui/core/styles";

import {
	Avatar,
	IconButton,
	Paper,
	Typography,
	Link,
	Tooltip
} from "@material-ui/core";

import {
	Person,
	Edit,
	WhatsApp,
	FileCopy,
	DeleteOutline,
	AddCircle
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

import api from "../../services/api";
import TableRowSkeleton from "../TableRowSkeleton";

import ContactLinkModal from "../ContactLinkModal";
import ConfirmationModal from "../ConfirmationModal";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		marginLeft: "5px",
	},
	drawerPaper: {
		marginLeft: "5px",
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.05)",
		borderRight: "1px solid rgba(0, 0, 0, 0.05)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
		backgroundColor: "rgba(0, 0, 0, 0.05)",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "rgba(0, 0, 0, 0.05)",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 160,
		height: 160,
	},

	contactHeader: {

		padding: 3,
		paddingTop: 10,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 2,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
	copy: {
		marginLeft: 2,
		cursor: "pointer",
	},
	root: {
		marginBottom: 5,
	},
	title: {
		display: "flex",
		// fontSize: 13,
		// textShadow: '1px 1px 1px black',
		fontWeight: 500,
		color: 'black',
		textAlign: 'center',
	},
	pos: {
		marginBottom: 12,
	},

	infoTag: {
		flex: 1,
		display: "flex",
		color: "gray",
		paddingTop: 3,
		paddingLeft: 5,
		marginRight: 1,
		borderRadius: 3,
	},
	infoTagMany: {
		fontSize: 13,
		borderBottom: "1px solid #e9e9e9",
		display: "flex",
		color: "gray",
		// paddingBotton: 3,
		flexFlow: "row nowrap",
		justifySelf: "center",
		alignSelf: "center",
	},
	infoTagBlue: {
		color: "RoyalBlue",
	},
	infoTagGreen: {
		color: "#00a859",
	},
	infoButton: {
		paddingRight: 5,
		cursor: "pointer",
	},
	avatar: {
		justifyContent: "left",
		verticalAalign: 'middle',
		width: 50,
		height: 50,
		borderRadius: 50,
		objectFit: "cover",
	}
}));


const ContactDrawerLink = ({ contact }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);


	const [deletingContactLinks, setDeletingContactLinks] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [selectedContactLinks, setSelectedContactLinks] = useState(null);
	const [contactLinkModalOpen, setContactLinkModalOpen] = useState(false);
	const [contactLinks, setContactLinks] = useState([]);
	const [selectedContact, setSelectedContact] = useState(null);
	const [contactleft, setContactleft] = useState(true);
	const [contactNumber, setContactNumber] = useState(contact.number);

	const handleOpenContactLinkModal = () => {
		setSelectedContact(contact)
		setSelectedContactLinks(null);
		setContactLinkModalOpen(true);
	};

	const handleCloseContactLinkModal = () => {
		setSelectedContactLinks(null);
		setContactLinkModalOpen(false);
		fetchContactsLinks();
	};

	const handleEditContactLinks = (contactLink) => {
		setSelectedContact(contact)
		setSelectedContactLinks(contactLink);
		setContactLinkModalOpen(true);
	};

	new ClipboardJS('#copy');

	useEffect(() => {

		const fetchContactNumber = async () => {

			const getContactNumber = await contact;
			try {
				var numberDDI = getContactNumber.number.substr(0, 2);
				var numberDDD = getContactNumber.number.substr(2, 2);
				var numberWZap1;
				var numberWZap2;

				if (numberDDI === "55") {

					numberWZap1 = getContactNumber.number.substr(-8, 4);
					numberWZap2 = getContactNumber.number.substr(-4, 4);
					if (numberDDD <= 30) {
						setContactNumber(`${numberDDI} ${numberDDD} 9${numberWZap1}-${numberWZap2}`);
					} else {
						setContactNumber(`${numberDDI} ${numberDDD} ${numberWZap1}-${numberWZap2}`);
					}

				} else {
					setContactNumber(getContactNumber.number);
				}
			} catch (error) {
				setContactNumber(getContactNumber.number);
			}

		};

		// eslint-disable-next-line
		contact?.id ? fetchContactNumber() : "";

	}, [contact]);

	const fetchContactsLinks = async () => {
		try {
		setLoading(true);
		// const { data } = await api.get("contacts/list");
		const { data } = await api.get(`/contactLinks/list/${contact?.id}`);

		// let customList = await data.map((c) => ({ "id": c.contactLinkId, "name": c.contactLinkIdShow }));
		// if (isArray(customList)) {
		setContactLinks(data);
		// }

		setLoading(false);
		} catch (err) {
			toastError(err);
		}
	};

	useEffect(() => {

		const delayDebounceFn = setTimeout(() => {

			// eslint-disable-next-line
			{ contact.id > 0 && fetchContactsLinks(); }
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [contact]);

	const handleDeleteContactLinks = async (id) => {
		setLoading("Excluindo...");
		try {
			await api.delete(`/contactLinks/${id}`);
			toast.success(i18n.t("global.toasts.deleted"));
			await fetchContactsLinks();
			setLoading(false);

		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		setDeletingContactLinks(null);
	};


	return (
		loading ? (
			<TableRowSkeleton columns={4} />
		) : (

			<Paper square variant="outlined" className={classes.contactDetails}>
				<ConfirmationModal
					title={
						deletingContactLinks &&
						`${i18n.t("global.deleteTitle")} ${i18n.t("contactLink.name")}: ${deletingContactLinks.name}?`
					}
					open={confirmModalOpen}
					onClose={setConfirmModalOpen}
					onConfirm={() => handleDeleteContactLinks(deletingContactLinks.id)}
				>
					{i18n.t("global.deleteMessage")}
				</ConfirmationModal>

				<ContactLinkModal
					open={contactLinkModalOpen}
					onClose={handleCloseContactLinkModal}
					aria-labelledby="form-dialog-title"
					contact={selectedContact}
					contactLink={selectedContactLinks}
				></ContactLinkModal>

				<Typography variant="subtitle1" style={{ borderBottom: "1px solid gray" }} >
					{i18n.t("contactLink.title")}
					<IconButton
						size="small"
						onClick={() => handleOpenContactLinkModal()}
					>
						<Tooltip title={i18n.t("global.buttons.new")} >
							<AddCircle color="primary" />
						</Tooltip>
					</IconButton>
				</Typography>
				{contactLinks?.map(link => (
					<>
						<div key={link?.id} style={{ borderBottom: "1px solid silver", display: "flex", justifyContent: "left", padding: 5, marginTop: 15 }}>
							<div style={{ justifyContent: "center", paddingLeft: 5 }}>
								<Avatar
									alt={link?.contactId === contact?.id ? link?.contactLinkIdName : link?.contactIdName}
									src={link?.contactId === contact?.id ? link?.contactLinkIdprofilePicUrl : link?.contactIdprofilePicUrl}
									className={classes.avatar}
								></Avatar>
							</div>

							<div className={classes.infoTagsData} style={{ paddingLeft: 10 }}>
								<div className={classes.infoTag}>
									<Person className={`${classes.infoButton} ${classes.infoTagBlue}`} />
									<Typography>{link?.contactId === contact?.id ? link?.contactLinkIdName : link?.contactIdName}&nbsp; </Typography>
								</div>

								<div className={classes.infoTag}>
									<WhatsApp className={`${classes.infoButton} ${classes.infoTagGreen}`} />
									<Link id={`contactLinkNumber${link?.contactId === contact?.id ? link?.contactLinkId : link?.contactId}`} href={`tel:${link?.contactLinkIdNumber}`}>
										{link?.contactId === contact?.id ? link?.contactLinkIdNumber : link?.contactIdNumber}
									</Link>
									<FileCopy
										id="copy"
										className={classes.copy}
										data-clipboard-action="copy"
										data-clipboard-target={`#contactLinkNumber${link?.contactId === contact?.id ? link?.contactLinkId : link?.contactId}`}
									// fontSize="small"
									/>
									<IconButton
										size="small"
										onClick={() => handleEditContactLinks(link)}
									>
										<Tooltip title={i18n.t("global.buttons.new")} >
											<Edit color="primary" />
										</Tooltip>
									</IconButton>
									<IconButton
										size="small"
										onClick={(e) => {
											setConfirmModalOpen(true);
											setDeletingContactLinks({
												name: link?.contactId === contact?.id ? link?.contactLinkIdName : link?.contactIdName,
												id: link?.id
											})
										}}
									>
										<DeleteOutline color="secondary" />
									</IconButton>
								</div>

							</div>
						</div>
						<div style={{ borderBottom: "1px solid gray", display: "flex", justifyContent: "left", padding: 5, marginTop: 5 }}>
							{link?.description}
						</div>
					</>


				))}

			</Paper>

		)
	);
};

export default ContactDrawerLink;
