import React, { useEffect, useState, useContext } from "react";
import ClipboardJS from "clipboard";

import { makeStyles } from "@material-ui/core/styles";

import {
	Avatar,
	IconButton,
	Paper,
	Typography,
	InputLabel,
	Link,
	Drawer,
	Button,
	Tooltip
} from "@material-ui/core";

import {
	Person,
	Edit,
	WhatsApp,
	FileCopy,
	Close
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import ContactModal from "../ContactModal";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";

import { SettingsContext } from "../../context/Settings/SettingsContext";

import ContactDrawerSubscription from "../ContactDrawerSubscription";
import ContactDrawerLink from "../ContactDrawerLink";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		marginLeft: "5px",
	},
	drawerPaper: {
		marginLeft: "5px",
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.05)",
		borderRight: "1px solid rgba(0, 0, 0, 0.05)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
		backgroundColor: "rgba(0, 0, 0, 0.05)",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "rgba(0, 0, 0, 0.05)",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 160,
		height: 160,
	},

	contactHeader: {

		padding: 3,
		paddingTop: 10,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 2,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
	copy: {
		marginLeft: 2,
		cursor: "pointer",
	},
	root: {
		marginBottom: 5,
	},
	title: {
		display: "flex",
		// fontSize: 13,
		// textShadow: '1px 1px 1px black',
		fontWeight: 500,
		color: 'black',
		textAlign: 'center',
	},
	pos: {
		marginBottom: 12,
	},

	infoTag: {
		flex: 1,
		display: "flex",
		color: "gray",
		paddingTop: 3,
		paddingLeft: 5,
		marginRight: 1,
		borderRadius: 3,
	},
	infoTagMany: {
		fontSize: 13,
		borderBottom: "1px solid #e9e9e9",
		display: "flex",
		color: "gray",
		// paddingBotton: 3,
		flexFlow: "row nowrap",
		justifySelf: "center",
		alignSelf: "center",
	},
	infoTagBlue: {
		color: "RoyalBlue",
	},
	infoTagGreen: {
		color: "#00a859",
	},
	infoButton: {
		paddingRight: 5,
		cursor: "pointer",
	},
	avatar: {
		justifyContent: "left",
		verticalAalign: 'middle',
		width: 50,
		height: 50,
		borderRadius: 50,
		objectFit: "cover",
	}
}));


const ContactDrawer = ({ open, handleDrawerClose, contact, loading }) => {
	const classes = useStyles();
	const { settings } = useContext(SettingsContext);

	const [modalOpen, setModalOpen] = useState(false);
	const [contactNumber, setContactNumber] = useState(contact.number);
	const [modulesFinancial, setModulesFinancial] = useState();
	const [modulesStreaming, setModulesStreaming] = useState();

	useEffect(() => {
		const getModules = async () => {
			const modulesFinancial = await settings.find(t => t.key === "modulesFinancial");
			setModulesFinancial(modulesFinancial?.value);
			const modulesStreaming = await settings.find(t => t.key === "modulesStreaming");
			setModulesStreaming(modulesStreaming?.value);
		}
		getModules();


	}, [settings]);


	new ClipboardJS('#copy');

	useEffect(() => {

		const fetchContactNumber = async () => {

			const getContactNumber = await contact;
			try {
				var numberDDI = getContactNumber.number.substr(0, 2);
				var numberDDD = getContactNumber.number.substr(2, 2);
				var numberWZap1;
				var numberWZap2;

				if (numberDDI === "55") {

					numberWZap1 = getContactNumber.number.substr(-8, 4);
					numberWZap2 = getContactNumber.number.substr(-4, 4);
					if (numberDDD <= 30) {
						setContactNumber(`${numberDDI} ${numberDDD} 9${numberWZap1}-${numberWZap2}`);
					} else {
						setContactNumber(`${numberDDI} ${numberDDD} ${numberWZap1}-${numberWZap2}`);
					}

				} else {
					setContactNumber(getContactNumber.number);
				}
			} catch (error) {
				setContactNumber(getContactNumber.number);
			}

		};

		// eslint-disable-next-line
		contact?.id ? fetchContactNumber() : "";

	}, [contact]);


	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>

			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (

				<div className={classes.content}>
					<ContactModal
						open={modalOpen}
						onClose={() => setModalOpen(false)}
						contactId={contact.id}
					></ContactModal>
					<Paper square variant="outlined" className={classes.contactHeader}>

						<div style={{ display: "flex", justifyContent: "left", paddingLeft: 5, marginTop: 20 }}>
							<div style={{ justifyContent: "center", paddingLeft: 5 }}>

								<Avatar
									alt={contact.name}
									src={contact?.profilePicUrl}
									className={classes.avatar}
								></Avatar>


							</div>

							<div className={classes.infoTagsData} style={{ paddingLeft: 10 }}>

								<div className={classes.infoTag}>
									<Person className={`${classes.infoButton} ${classes.infoTagBlue}`} />
									<Typography>{contact?.name}&nbsp; </Typography>
								</div>

								<div className={classes.infoTag}>
									<WhatsApp className={`${classes.infoButton} ${classes.infoTagGreen}`} />
									<Link id="contactNumber" href={`tel:${contact.number}`}>
										{contactNumber ? contactNumber : contact.number}
									</Link>
									<FileCopy
										id="copy"
										className={classes.copy}
										data-clipboard-action="copy"
										data-clipboard-target="#contactNumber"
									// fontSize="small"
									/>
								</div>

							</div>

						</div>
						<div style={{ display: "flex", justifyContent: "center", paddingLeft: 5, marginTop: 20 }}>

							<Button
								size="small"
								variant="outlined"
								color="primary"
								onClick={() => setModalOpen(true)}
							>
								<Edit size="small" style={{ color: '#808080' }} /> {i18n.t("global.buttons.edit")}
							</Button>

						</div>

						<IconButton onClick={handleDrawerClose} style={{ position: "absolute", top: 5, right: 8 }}>
							<Tooltip title={i18n.t("global.buttons.close")} >
								<Close />
							</Tooltip>
						</IconButton>

					</Paper>

					{modulesFinancial === "enabled" &&
						<ContactDrawerSubscription contact={contact} modules={{ modulesFinancial, modulesStreaming }} />
					}


					<Paper square variant="outlined" className={classes.contactDetails}>
						<Typography variant="subtitle1">
							{i18n.t("contactDrawer.extraInfo")}
						</Typography>
						{contact?.email && (
							<Typography>
								<Link id="contactNumber" href={`mailto:${contact.email}`}>{contact.email}</Link>
							</Typography>
						)}
						{contact?.extraInfo?.map(info => (
							<Paper
								key={info.id}
								square
								variant="outlined"
								className={classes.contactExtraInfo}
							>
								<InputLabel>{info.name}</InputLabel>
								<Typography component="div" noWrap style={{ paddingTop: 2 }}>
									<MarkdownWrapper>{info.value}</MarkdownWrapper>
								</Typography>
							</Paper>
						))}
					</Paper>

					<ContactDrawerLink  contact={contact} />
				</div>
			)
			}
		</Drawer >
	);
};

export default ContactDrawer;
