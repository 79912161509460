import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";


import {
  Hidden,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Edit, DeleteOutline, CheckCircle, Search, AddBox } from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import PlanModal from "../../components/PlanModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import PriceBRL from "../../components/PriceBRL";

const reducer = (state, action) => {
  if (action.type === "LOAD_PLANS") {
    const plans = action.payload;
    const newPlans = [];

    plans.forEach((plan) => {
      const planIndex = state.findIndex((q) => q.id === plan.id);
      if (planIndex !== -1) {
        state[planIndex] = plan;
      } else {
        newPlans.push(plan);
      }
    });

    return [...state, ...newPlans];
  }

  if (action.type === "UPDATE_PLANS") {
    const plan = action.payload;
    const planIndex = state.findIndex((q) => q.id === plan.id);

    if (planIndex !== -1) {
      state[planIndex] = plan;
      return [...state];
    } else {
      return [plan, ...state];
    }
  }

  if (action.type === "DELETE_PLANS") {
    const planId = action.payload;

    const planIndex = state.findIndex((q) => q.id === planId);
    if (planIndex !== -1) {
      state.splice(planIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));


const Plans = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [plans, dispatch] = useReducer(reducer, []);
  const [selectedPlans, setSelectedPlans] = useState(null);
  const [plansModalOpen, setPlanModalOpen] = useState(false);
  const [deletingPlans, setDeletingPlans] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchPlans = async () => {
        try {
          const { data } = await api.get("/plan/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_PLANS", payload: data.plans });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchPlans();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {


    const socket = openSocket();

    socket.on("plan", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_PLANS", payload: data.plan });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_PLANS",
          payload: +data.planId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenPlanModal = () => {
    setSelectedPlans(null);
    setPlanModalOpen(true);
  };

  const handleClosePlanModal = () => {
    setSelectedPlans(null);
    setPlanModalOpen(false);
  };

  const handleEditPlans = (plan) => {
    setSelectedPlans(plan);
    setPlanModalOpen(true);
  };

  const handleDeletePlans = async (planId) => {
    try {
      await api.delete(`/plan/${planId}`);
      toast.success(i18n.t("global.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingPlans(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingPlans &&
          `${i18n.t("global.deleteTitle")} ${deletingPlans.shortcut
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeletePlans(deletingPlans.id)}
      >
        {i18n.t("global.deleteMessage")}
      </ConfirmationModal>
      <PlanModal
        open={plansModalOpen}
        onClose={handleClosePlanModal}
        aria-labelledby="form-dialog-title"
        planId={selectedPlans && selectedPlans.id}
      ></PlanModal>
      <MainHeader>
        <Title>{i18n.t("plan.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Hidden mdDown>
            <TextField
              placeholder={i18n.t("global.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Hidden>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPlanModal}
          >
            <AddBox /> <Hidden mdDown>{i18n.t("global.buttons.add")}</Hidden>
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Hidden lgUp>
        <TextField
          placeholder={i18n.t("global.searchPlaceholder")}
          type="search"
          value={searchParam}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search style={{ color: "gray" }} />
              </InputAdornment>
            ),
          }}
        />
        <br />
      </Hidden>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("global.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("global.description")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("global.price")}
              </TableCell>
              <TableCell align="center">
                Status
              </TableCell>
              <TableCell align="center">
                {i18n.t("global.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {plans.map((plan) => (
                <TableRow key={plan.id}>
                  <TableCell align="center">{plan.name}</TableCell>
                  <TableCell align="center">{plan.description}</TableCell>
                  <TableCell align="center"><PriceBRL value={plan?.price} /></TableCell>
                  <TableCell align="center">
                    {plan.status
                      ? <CheckCircle style={{ color: "green" }} />
                      : <CheckCircle style={{ color: "grey" }} />
                    }
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditPlans(plan)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingPlans(plan);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Plans;
