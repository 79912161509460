import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  InputAdornment
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18n.t("validation.too_short"))
    .max(30, i18n.t("validation.too_long"))
    .required(i18n.t("validation.required")),
  price: Yup.string()
    .min(2, i18n.t("validation.too_short"))
    .required(i18n.t("validation.required")),
});

const PlanModal = ({ open, onClose, planId }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    description: "",
    price: 0,
    status: true,
  };
  const [plan, setPlan] = useState(initialState);

  const changePrice = async (e) => {
    const valueString = `${e.price}`;
    const valueRemoved = valueString.replace(/\D/g, "");
    const sizeSlice = valueRemoved.length - 2;
    let newValue;
    if (sizeSlice === 0) {
      newValue = [valueRemoved.slice(sizeSlice), ',', "00"].join('');
    } else {
      newValue = [valueRemoved.slice(0, sizeSlice), ',', valueRemoved.slice(sizeSlice)].join('');
    }
    setPlan({ ...e, 'price': newValue });
  }; 

  useEffect(() => {
    const fetchSession = async () => {
      if (!planId) return;

      try {
        const { data } = await api.get(`plan/${planId}`);
        changePrice(data);

      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [planId]);

  const handleSavePlan = async (values) => {
    // const planData = { ...values, plan };
    try {
      if (planId) {
        await api.put(`/plan/${planId}`, values);
      } else {
        await api.post("/plan", values);
      }
      toast.success(i18n.t("global.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setPlan(initialState);
  };

  const handlePriceOnChange = (e) => {

    const valueString = `${e.target.value}`;
    const valueRemoved = valueString.replace(/\D/g, "");
    const sizeSlice = valueRemoved.length - 2;
    const newValue = [valueRemoved.slice(0, sizeSlice), ',', valueRemoved.slice(sizeSlice)].join('');

    return newValue;

  };


  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {planId
            ? `${i18n.t("global.buttons.edit")} ${i18n.t("plan.name")}`
            : `${i18n.t("global.buttons.add")} ${i18n.t("plan.name")}`
          }
        </DialogTitle>
        <Formik
          initialValues={plan}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSavePlan(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ setFieldValue, values, touched, errors, isSubmitting }) => (

            <Form   >
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("global.name")}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="status"
                        checked={values.status}
                      />
                    }
                    label="Status"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("global.description")}
                    multiline
                    minRows={5}
                    fullWidth
                    name="description"
                    error={
                      touched.description && Boolean(errors.description)
                    }
                    helperText={
                      touched.description && errors.description
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>

                  <Field
                    as={TextField}
                    label={i18n.t("global.price")}
                    fullWidth
                    name="price"
                    error={
                      touched.price && Boolean(errors.price)
                    }
                    helperText={
                      touched.price && errors.price
                    }
                    variant="outlined"
                    margin="dense"
                    onChange={(value) => {
                      setFieldValue("price", handlePriceOnChange(value));
                    }}
                    InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                  />
                </div>

              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("global.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {planId
                    ? i18n.t("global.buttons.okEdit")
                    : i18n.t("global.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(PlanModal);
