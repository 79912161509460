const messages = {
  es: {
    translations: {
      signup: {
        title: "Registro",
        toasts: {
          success:
            "¡El usuario ha sido creado satisfactoriamente! ¡Ahora inicia sesión!",
          fail: "Error creando el usuario. Verifica la data reportada.",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Regístrate",
          login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
        },
      },
      login: {
        title: "Inicio de Sesión",
        textAccessCode: "Le hemos enviado un código de acceso a su correo electrónico, por favor revise su correo electrónico. Si no lo encuentras revisa tu carpeta de spam",
        form: {
          email: "Correo Electrónico",
          password: "Contraseña",
          accessCode: "Código de acceso",
        },
        buttons: {
          submit: "Ingresa",
          register: "¿No tienes cuenta? ¡Regístrate!",
        },
      },
      auth: {
        toasts: {
          success: "¡Inicio de sesión exitoso!",
        },
      },

      reports: {
        filters: {
          today: "Hoy",
          days: "Días",
          month: "Mês",
          filterBy: "Filtrar Por",
          modal: {
            title: "Más opciones de filtro",
            dateInitial: "Fecha Inicial",
            dateFinal: "Fecha finalización",
            button: {
              title: "Filtro"
            }
          }
        },
        charts: {
          yearly: {
            title: "Servicios Finalizados en"
          }
        },
        usersRankinglist: {
          title: "Ranking de servicios",
          name: "Nome"
        },
        status: {
          inAttendance: "En servicio",
          waiting: "Esperando",
          closed: "cerrado",
          unassigned: "Sin asignar"
        },
      },

      dashboard: {
        charts: {
          perDay: {
            title: "Tickets hoy: ",
          },
        },
        messages: {
          inAttendance: {
            title: "En servicio"
          },
          waiting: {
            title: "Esperando"
          },
          closed: {
            title: "Finalizado"
          }
        }
      },
      connections: {
        title: "Conexiones",
        toasts: {
          deleted:
            "¡La conexión de WhatsApp ha sido borrada satisfactoriamente!",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage: "¿Estás seguro? Este proceso no puede ser revertido.",
          disconnectTitle: "Desconectar",
          disconnectMessage: "Estás seguro? Deberá volver a leer el código QR",
        },
        buttons: {
          add: "Agrega WhatsApp",
          disconnect: "Desconectar",
          tryAgain: "Inténtalo de nuevo",
          qrcode: "QR CODE",
          newQr: "Nuevo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "No se pudo iniciar la sesión de WhatsApp",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y vuelva a intentarlo o solicite un nuevo código QR",
          },
          qrcode: {
            title: "Esperando la lectura del código QR",
            content:
              "Haga clic en el botón 'CÓDIGO QR' y lea el Código QR con su teléfono celular para iniciar la sesión",
          },
          connected: {
            title: "Conexión establecida",
          },
          timeout: {
            title: "Se perdió la conexión con el teléfono celular",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y que WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR",
          },
        },
        table: {
          name: "Nombre",
          status: "Estado",
          number: "Número",
          lastUpdate: "Última Actualización",
          default: "Por Defecto",
          actions: "Acciones",
          session: "Sesión",
        },
      },
      whatsappModal: {
        title: {
          add: "Agrega WhatsApp",
          edit: "Edita WhatsApp",
        },
        form: {
          name: "Nombre",
          default: "Por Defecto",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "WhatsApp guardado satisfactoriamente.",
      },
      qrCode: {
        message: "Lée el código QR para empezar la sesión.",
      },
      contacts: {
        title: "Contactos",
        toasts: {
          deleted: "¡Contacto borrado satisfactoriamente!",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteAllTitle: "Borrar todo",
          importTitle: "Importar contactos",
          deleteMessage: "¿Está seguro de que desea eliminar este contacto? Se perderán todos los tickets relacionados.",
          deleteAllMessage: "¿Está seguro de que desea eliminar todos los contactos? Se perderán todos los tickets relacionados.",
          importMessage: "¿Quieres importar todos los contactos del teléfono?",
        },
        buttons: {
          import: "Importar contactos",
          add: "Agregar contacto",
          export: "Exportar contactos",
          delete: "Eliminar todos los contactos"
        },
        table: {
          name: "Nombre",
          whatsapp: "WhatsApp",
          email: "Correo Electrónico",
          actions: "Acciones",
        },
      },
      contactModal: {
        title: {
          add: "Agregar contacto",
          edit: "Editar contacto",
        },
        form: {
          mainInfo: "Detalles del contacto",
          extraInfo: "Información adicional",
          name: "Nombre",
          number: "Número de Whatsapp",
          email: "Correo Electrónico",
          extraName: "Nombre del Campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Agregar información",
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Contacto guardado satisfactoriamente.",
      },
      quickAnswersModal: {
        title: {
          add: "Agregar respuesta rápida",
          edit: "Editar respuesta rápida",
        },
        form: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Respuesta rápida guardada correctamente.",
        tip: "Escriba @ para opciones variables."
      },
      planModal: {
        title: {
          add: "Agregar Plan",
          edit: "Editar Plan",
        },
        form: {
          name: "Nombre",
          description: "Descripción",
          price: "Precio",
          status: "Activo",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Plan guardado exitosamente."
      },
      queueModal: {
        title: {
          add: "Agregar cola",
          edit: "Editar cola",
        },
        form: {
          name: "Nombre",
          color: "Color",
          order: "Ordem",
          greetingMessage: "Mensaje de saludo",
        },
        buttons: {
          okAdd: "Añadir",
          okEdit: "Ahorrar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Agregar usuario",
          edit: "Editar usuario",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
          profile: "Perfil",
          whatsapp: "Conexión estándar",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Usuario guardado satisfactoriamente.",
      },
      chat: {
        noTicketMessage: "Selecciona un ticket para empezar a chatear.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "ARRASTRA Y SOLTA LOS ARCHIVOS EN EL CAMPO DE ABAJO",
          titleFileList: "List of file(s)"
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "Nuevo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Sectores",
      },
      tickets: {
        toasts: {
          deleted: "El ticket en el que estabas ha sido borrado.",
        },
        notification: {
          message: "Mensaje de",
        },
        tabs: {
          open: { title: "Bandeja" },
          closed: { title: "Resueltos" },
          search: { title: "Buscar" },
        },
        search: {
          placeholder: "Buscar tickets y mensajes.",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Escriba para buscar usuarios",
        fieldQueueLabel: "Transferir a la cola",
        fieldConnectionLabel: "Transferir to conexión",
        fieldQueuePlaceholder: "Seleccione una cola",
        fieldConnectionPlaceholder: "Seleccione una conexión",
        noOptions: "No se encontraron usuarios con ese nombre",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Cola",
        assignedHeader: "Trabajando en",
        noTicketsTitle: "¡Nada acá!",
        connectionTitle: "Conexión que se está utilizando actualmente.",
        userTitle: "Nombre de usuario asignado a este ticket.",
        noTicketsMessage:
          "No se encontraron tickets con este estado o término de búsqueda",
        buttons: {
          assignMe: "Aasignarme",
          accept: "Atender",
          acceptBeforeBot: "Atender",
          spy: "Spy",
          start: "Comienzo",
          cancel: "Cancelar"
        },
        acceptModal: {
          title: "aceptar servicio",
          queue: "elige la cola"
        }
      },
      newTicketModal: {
        title: "Crear Ticket",
        fieldLabel: "Escribe para buscar un contacto",
        add: "Añadir",
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          reports: "Informes",
          connections: "Conexiones",
          tickets: "Tickets",
          contacts: "Contactos",
          quickAnswers: "Respuestas rápidas",
          queues: "Sectores",
          tags: "Tags",
          api: "API",
          administration: "ADMINISTRACIÓN",
          users: "Usuarios",
          settings: "Configuración",
          version: "Version",
          super: {
            title: "Multi Clientes",
            tennants: "Gerenciar Clientes",
          },
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Cerrar Sesión",
          },
        },
      },
      notifications: {
        noTickets: "Sin notificaciones.",
      },
      queues: {
        title: "Linhas",
        table: {
          name: "Nombre",
          color: "Color",
          order: "Ordem",
          greeting: "Mensaje de saludo",
          actions: "Comportamiento",
        },
        buttons: {
          add: "Agregar cola",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "¿Estás seguro? ¡Esta acción no se puede revertir! Los tickets en esa cola seguirán existiendo, pero ya no tendrán ninguna cola asignada.",
        },
      },
      queueSelect: {
        inputLabel: "Linhas",
      },
      quickAnswers: {
        title: "Respuestas rápidas",
        table: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar respuesta rápida",
        },
        toasts: {
          deleted: "Respuesta rápida eliminada correctamente",
        },
        searchPlaceholder: "Buscar ...",
        confirmationModal: {
          deleteTitle:
            "¿Está seguro de que desea eliminar esta respuesta rápida?",
          deleteMessage: "Esta acción no se puede deshacer.",
        },
      },
      plans: {
        title: "Planos",
        table: {
          name: "Nombre",
          description: "Descripción",
          price: "Precio",
          status: "Activo",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar plan",
        },
        toasts: {
          deleted: "Plan eliminado exitosamente.",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle:
          "¿Está seguro de que desea eliminar esta respuesta rápida?",
          deleteMessage: "Esta acción no se puede deshacer.",
        },
      },
      users: {
        title: "Usuarios",
        table: {
          name: "Nombre",
          email: "Correo Electrónico",
          profile: {
            title: "Perfil",
            role: {
              admin: "Administrador",
              dashboard: "Informes",
              finance: "Financiero",
              super: "Gerente del Sistema",
              supervisor: "Supervisor",
              user: "Usuario",
            },
          },
          whatsapp: "Conexión estándar",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar usuario",
        },
        toasts: {
          deleted: "Usuario borrado satisfactoriamente.",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage:
            "Toda la información del usuario se perderá. Los tickets abiertos de los usuarios se moverán a la cola.",
        },
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle:
            "¿Está seguro de que desea eliminar esta?",
          deleteMessage: "Esta acción no se puede deshacer.",
        },
        confirmModalDeleteContact: {
          deleteTitle:
            "¿Está seguro de que desea desvincular este contacto de etiqueta?",
          deleteMessage: "Esta acción no se puede deshacer.",
        },
        table: {
          name: "Name",
          color: "Color",
          tickets: "Registro w Tags",
          actions: "Acciones",
        },
        buttons: {
          add: "Nova Tag",
        },
        forms: {
          inputLabel: "Tags"
        },
        toasts: {
          deleted: "Tag borrado satisfactoriamente.",
          contactDeleted: "contacto borrado satisfactoriamente.",
        },
        info: {
          minimumCharacters: "Introduzca al menos 3 caracteres",
        }
      },
      settings: {
        success: "Configuración guardada satisfactoriamente.",
        title: "Configuración",
        support: "Soporte",
        activate: "Ativar",
        settings: {
          title: "Gerenciar Configuraciones del Sistema",
          userCreation: {
            name: "Creación de usuarios",
            note: "Si está habilitado, usuarios podran generar nuevos cadastros desde la pagina inicial.",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          timeCreateNewTicket: {
            name: "MENSAJE DE BIENVENIDA DESPUÉS",
            note: "Selecciona el tiempo que tardará en abrir un nuevo ticket si el cliente te vuelve a contactar",
            options: {
              "10": "10 Segundos",
              "30": "30 Segundos",
              "60": "1 minuto",
              "300": "5 minutos",
              "1800": "30 minutos",
              "3600": "1 hora",
              "7200": "2 horas",
              "21600": "6 horas",
              "43200": "12 horas",
              "86400": "24 horas",
              "172800": "48 horas",
            },
          },
          hoursCloseTicketAuto: {
            name: "CIERRE TICKET DESPUÉS DE (HORAS)",
            note: "Seleccione el tiempo, en horas, que los boletos se cerrarán automáticamente",
            options: {
              "0": "NUNCA",
              "1": "1 HORA",
              "2": "2 HORAS",
              "4": "4 HORAS",
              "6": "6 HORAS",
              "12": "12 HORAS",
              "24": "24 HORAS",
              "36": "36 HORAS",
              "48": "48 HORAS",
              "60": "60 HORAS",
              "72": "72 HORAS"
            },
          },
          call: {
            name: "ACEPTAR LLAMADAS",
            note: "Si está deshabilitado, el cliente recibirá un mensaje de que no acepta llamadas de voz/video",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          queuedTicketsOnly: {
            name: "SIN COLA: SOLO PARA ADMINISTRADOR",
            note: "Si está habilitado, solo el administrador podrá ver los tickets sin cola.",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          CheckMsgIsGroup: {
            name: "Ignorar Mensagens de Grupos",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          ticketWaitingNoShowMessagem: {
            name: "TICKET PENDENTE: NO MOSTRAR MENSAJE",
            note: "Ativado: si el ticket está pendente, no muestre el mensaje.",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          sendMessageToSelectQueue: {
            name: "MENSAJE PARA SELECCIONAR COLA",
            note: "Si está habilitado, los clientes recibirán un mensaje para seleccionar una cola del menú.",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          userSpyTicket: {
            name: "El usuario puede espiar el ticket",
            note: "Si está habilitado, los usuarios podrán ver los tickets en ESPERA.",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Asignado a:",
          buttons: {
            return: "Devolver",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceptar",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Escriba un mensaje o presione '' / '' para usar las respuestas rápidas registradas",
        placeholderClosed:
          "Vuelva a abrir o acepte este ticket para enviar un mensaje.",
        signMessage: "Firmar",
      },
      contactDrawer: {
        header: "Detalles del contacto",
        buttons: {
          edit: "Editar contacto",
        },
        extraInfo: "Otra información",
      },
      ticketOptionsMenu: {
        delete: "Borrar",
        transfer: "Transferir",
        confirmationModal: {
          title: "¿Borrar ticket #",
          titleFrom: "del contacto ",
          message:
            "¡Atención! Todos los mensajes Todos los mensajes relacionados con el ticket se perderán.",
        },
        buttons: {
          delete: "Borrar",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Borrar",
        reply: "Responder",
        confirmationModal: {
          title: "¿Borrar mensaje?",
          message: "Esta acción no puede ser revertida.",
        },
      },
      variables: {
        list: {
          // Contact vars
          contact_firstName: "Contacto: Fist name",
          contact_fullName: "Contacto: Full name",
          contact_email: "Contacto: E-mail",
          contact_phone: "Contacto: Telephone",
          contact_avatar: "Contacto: Avatar",
          contact_dateCreate: "Contacto: Date create",
          contact_dateUpdate: "Contacto: Date update",

          // Ticket vars
          ticket_id: "Ticket: ID",
          ticket_status: "Ticket: Status",
          ticket_lastMessage: "Ticket: Last message",
          ticket_dateCreation: "Ticket: Date createo",
          ticket_dateUpdate: "Ticket: Date update",

          // User vars: use if exists
          user_name: "User: Name",
          user_email: "User: E-mail",

          // Misc
          break_message: "Break message",
          greeting: "Greettinf",
          year: "Year",
          month: "Month",
          day: "Day",
          hour: "Hour",
          minute: "minute",
          second: "Segund",
          date_iso: "Date ISO",
          date_us: "Date US",
          date_br: "Date BR",
          protocol: "Protocol",
        }
      },
      super: {
        title: "Área Multi Clientes",
        settings: {
          title: "Gerenciar Configuraciones de Clientes",
          dueDate: {
            name: "Fecha de vencimiento",
            note: "Fecha de vencimiento",
          },
          billingUrl: {
            name: "Link para pagamento",
            note: "Link para pagamento",
          },
          billingPric: {
            name: "Valor para pagamento",
            note: "Valor para pagamento",
          },
          modulesCampaigns: {
            name: "Módulo de Campañas",
            note: "Módulo de Campañas",
            options: {
              nothing: "No instalado",
              install: "Instalar módulo",
              enabled: "Activado",
              disabled: "Desactivado",
            }
          },
          accessCode: {
            name: "Código de acceso al correo electrónico",
            note: "Solicitar Código de Acceso por Correo Electrónico",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            }
          },
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Debe haber al menos una conexión de WhatsApp predeterminada.",
        ERR_NO_PERMISSION_CREATE_CONNECTION_LIMIT: "Haz alcanzado el límite de creación de conexiones, comuníquese con Bits TI para adquirir licencias adicionales.",
        ERR_NO_DEF_WAPP_FOUND: "No se encontró WhatsApp predeterminado. Verifique la página de conexiones.",
        ERR_WAPP_NOT_INITIALIZED: "Esta sesión de WhatsApp no ​​está inicializada. Verifique la página de conexiones.",
        ERR_WAPP_CHECK_CONTACT: "No se pudo verificar el contacto de WhatsApp. Verifique la página de conexiones.",
        ERR_WAPP_INVALID_CONTACT: "Este no es un número de whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA: "No se pudieron descargar los medios de WhatsApp. Verifique la página de conexiones.",
        ERR_INVALID_CREDENTIALS: "Error de autenticación. Vuelva a intentarlo.",
        ERR_SENDING_WAPP_MSG: "Error al enviar el mensaje de WhatsApp. Verifique la página de conexiones.",
        ERR_DELETE_WAPP_MSG: "No se pudo borrar el mensaje de WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Ya hay un chat abierto para este contacto.",
        ERR_SESSION_EXPIRED: "Sesión caducada. Inicie sesión.",
        ERR_USER_CREATION_DISABLED: "La creación de usuarios fue deshabilitada por el administrador.",
        ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
        ERR_NO_PERMISSION_INDEX: "No tienes permiso para acceder a este recurso (INDEX).",
        ERR_NO_PERMISSION_UPDATE_SETTING: "No tienes permiso para actualizar este recurso.",
        ERR_NO_PERMISSION_UPDATE_USER: "No tienes permiso para actualizar usuarios.",
        ERR_NO_PERMISSION_CREATE_USER: "No tienes permiso para crear usuarios.",
        ERR_NO_PERMISSION_CREATE_USER_LIMIT: "Haz alcanzado el límite de creación de usuarios, comuníquese para adquirir licencias adicionales.",
        ERR_NO_PERMISSION_REMOVE_USER: "No tienes permiso para remover usuarios.",
        ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
        ERR_NO_SETTING_FOUND: "No se encontró ninguna configuración con este ID.",
        ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
        ERR_NO_TICKET_FOUND: "No se encontró ningún chat con este ID.",
        ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
        ERR_NO_WAPP_FOUND: "No se encontró WhatsApp con este ID.",
        ERR_CREATING_MESSAGE: "Error al crear el mensaje en la base de datos.",
        ERR_CREATING_TICKET: "Error al crear el chat en la base de datos.",
        ERR_FETCH_WAPP_MSG: "Error al obtener el mensaje en WhtasApp, tal vez sea demasiado antiguo.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "Este color ya está en uso, elija otro.",
        ERR_WAPP_GREETING_REQUIRED: "El mensaje de saludo es obligatorio cuando hay más de una cola.",
      },
    },
  },
};

export { messages };
