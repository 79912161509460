import React from "react"

import { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogContent
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

import { i18n } from "../../translate/i18n";

import { makeStyles } from "@material-ui/core/styles"

import CampaignsSaveListModal from "../../components/CampaignsSaveListModal";
import ModalImageCors from "../ModalImageCors";



import phoneView from "../../assets/phoneView.png";
import MarkdownWrapper from "../MarkdownWrapper";


const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",


  },

  textContentItem: {
    overflowWrap: "break-word",
    overflowY: "scroll",
    ...theme.scrollbarStyles,

    backgroundColor: "transparente", position: "absolute",


  },
  messageRight: {
    marginTop: 5,
    marginBottom: 5,
    padding: 5,
    display: "block",
    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    boxShadow: "0 1px 1px #b3b3b3",
    fontSize: "0.75rem"
  },
  right: {
    objectFit: "cover",
    marginTop: 5,
    marginBottom: 5,
    display: "block",

    alignSelf: "flex-end",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    padding: 5,
    "& img ": {
      height: "85%"
    }
  },
  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

}))

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const CampaignReportsModal = ({ open, onClose, campaignId }) => {

  const classes = useStyles()

  const [campaignsSaveTagsModalOpen, setCampaignSaveTagsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [campaignsMsg, setCampaignsMsg] = useState([]);



  const handleClose = () => {
    campaignId = [];
    onClose();
  };

  useEffect(() => {
    setWindowDimensions(getWindowDimensions());

    if (campaignId?.msg) {
      const inputMessageSplit = campaignId.msg
        .replace(/......(?<=value).*?(?=##)../g, "").replace(/....(?<=",).*?(?=@")../g, "")
        .split("[[{break_message}]]");
      setCampaignsMsg(inputMessageSplit);
    }

  }, [campaignId])


  const handleCloseCampaignSaveTagsModal = async () => {
    setSelectedData([]);
    setCampaignSaveTagsModalOpen(false);
  };

  function showMedias(m) {

    if (m.mimetype === 'image/jpeg' ||
      m.mimetype === 'image/png' ||
      m.mimetype === 'image/jpg' ||
      m.mimetype === 'image/gif') {
      return (
        <ModalImageCors imageUrl={m.url} />
      );
    } else if (m.mimetype === "audio/ogg" ||
      m.mimetype === "audio/mp3" ||
      m.mimetype === "audio/mpeg"
    ) {
      return (
        <audio controls>
          <source src={m.url} type="audio/ogg"></source>
        </audio>
      );
    } else if (m.mimetype === "video/mp4") {
      return (
        <video
          className={classes.messageMedia}
          src={m.url}
          controls
        />
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={m.url}
            >
              Download
            </Button>
          </div>
        </>
      );
    }




  }

  return (

    <div className={classes.root}>
      <Dialog
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "rgba(255,255,255,0.5)",
          },
        }}
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <CampaignsSaveListModal
          open={campaignsSaveTagsModalOpen}
          onClose={handleCloseCampaignSaveTagsModal}
          data={selectedData}
          aria-labelledby="form-dialog-title"
        />
        <DialogContent dividers >
          <Button
            onClick={handleClose}
            color="secondary"
            variant="contained"
            style={{ position: "absolute", right: "45%", left: "65%" }}
          >
            {i18n.t("campaignsRepostsModal.close")}
          </Button>
          <div className={classes.container}>

            {/* 
            tela grande: 976 
            tele 20': 691
          */}
            <img src={phoneView} style={{
              height:
                windowDimensions.height > 900
                  ? 900 + "px"
                  : windowDimensions.height > 720
                    ? 690 + "px"
                    : windowDimensions.height > 640
                      ? 605 + "px"
                      : windowDimensions.height > 540
                        ? 495 + "px"
                        : 430 + "px",

            }}
            />

            <div
              className={classes.textContentItem}
              style={{
                top:
                  windowDimensions.height > 900
                    ? 123 + "px"
                    : windowDimensions.height > 720
                      ? 100 + "px"
                      : windowDimensions.height > 640
                        ? 90 + "px"
                        : windowDimensions.height > 540
                          ? 77 + "px"
                          : 70 + "px",
                height:
                  windowDimensions.height > 900
                    ? 665 + "px"
                    : windowDimensions.height > 720
                      ? 510 + "px"
                      : windowDimensions.height > 640
                        ? 447 + "px"
                        : windowDimensions.height > 540
                          ? 366 + "px"
                          : 318 + "px",
                width:
                  windowDimensions.height > 900
                    ? 395 + "px"
                    : windowDimensions.height > 720
                      ? 303 + "px"
                      : windowDimensions.height > 640
                        ? 266 + "px"
                        : windowDimensions.height > 540
                          ? 217 + "px"
                          : 189 + "px",
                paddingLeft:
                  windowDimensions.height > 900
                    ? 80 + "px"
                    : windowDimensions.height > 720
                      ? 65 + "px"
                      : windowDimensions.height > 640
                        ? 55 + "px"
                        : windowDimensions.height > 540
                          ? 45 + "px"
                          : 40 + "px",


              }}
            >
              {campaignId?.medias.length > 0 && campaignId?.caption === false &&

                campaignId?.medias.map((t) => (
                  <div className={classes.right} >
                    {showMedias(t)}
                  </div>
                ))

              }
              {campaignsMsg.map((t, index) => (
                <div className={classes.messageRight} key={index} >

                  {campaignId?.medias.length === 1 && campaignId?.caption &&
                    campaignId?.medias.map((m) => (
                      <div className={classes.right} >
                        {showMedias(m)}
                      </div>
                    ))

                  }
                  <MarkdownWrapper>{t.trim()}</MarkdownWrapper>

                </div>
              ))[0]}
              {campaignsMsg.slice(1).map((t, index) => (
                <div className={classes.messageRight} key={index} >

                  <MarkdownWrapper>{t.trim()}</MarkdownWrapper>

                </div>
              ))}
            </div>

          </div>

        </DialogContent>
      </Dialog>
    </div >
  )


}

export default CampaignReportsModal