import { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const reducer = (state, action) => {
	if (action.type === "LOAD_SETTINGS") {
		const settings = action.payload;

		return [...settings];
	}

	if (action.type === "UPDATE_SETTINGS") {
		
		const settings = action.payload;
		const settingsIndex = state.findIndex(s => s.id === settings.id);

		if (settingsIndex !== -1) {
			state[settingsIndex] = settings;
			return [...state];
		} else {
			return [settings, ...state];
		}
	}

	if (action.type === "UPDATE_SESSION") {
		const settings = action.payload;
		const settingsIndex = state.findIndex(s => s.id === settings.id);

		if (settingsIndex !== -1) {
			state[settingsIndex].status = settings.status;
			state[settingsIndex].updatedAt = settings.updatedAt;
			state[settingsIndex].qrcode = settings.qrcode;
			state[settingsIndex].retries = settings.retries;
			return [...state];
		} else {
			return [...state];
		}
	}

	if (action.type === "DELETE_SETTINGS") {
		const settingsId = action.payload;

		const settingsIndex = state.findIndex(s => s.id === settingsId);
		if (settingsIndex !== -1) {
			state.splice(settingsIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useSettings = () => {
	const [settings, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings/");
				dispatch({ type: "LOAD_SETTINGS", payload: data });
				setLoading(false);
			} catch (err) {
				setLoading(false);
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket();

		socket.on("settings", data => {

			if (data.action === "update" || data.action === "create") {
				dispatch({ type: "UPDATE_SETTINGS", payload: data.setting });
			} 
		});

		socket.on("settings", data => {
			if (data.action === "delete") {
				dispatch({ type: "DELETE_SETTINGS", payload: data.settingsId });
			}
		});

		socket.on("settingsSession", data => {
			if (data.action === "update") {
				dispatch({ type: "UPDATE_SESSION", payload: data.session });
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	return { settings, loading };
};

export default useSettings;



// import api from "../../services/api";

// const useSettings = (key) => {
// 	const findAll = async () => {
//         const { data } = await api.get("/settings");
//         return data;
//     }

// 	return { findAll };
// };

// export default useSettings;
